import { createEffect } from "effector";

import { GapDomain } from "@/domains/GapDomain";
import { getGapUseCase } from "@/useCases/gap/getGap/GetGapUseCase";

export interface GetGapProps {
  accountId: string;
  vendorId: string;
}

export const getGap = createEffect({
  async handler(props: GetGapProps): Promise<GapDomain> {
    return await getGapUseCase(props);
  },
});
