import { TimeParts } from "@/@types/TimeValue";

export const convertTimeInSecond = (time: TimeParts) => {
  let totalSeconds = 0;

  if (time.d > 0) {
    const dayToSecond = time.d * 86400;
    totalSeconds += dayToSecond;
  }

  if (time.h > 0) {
    const hourToSecond = time.h * 3600;
    totalSeconds += hourToSecond;
  }

  if (time.m > 0) {
    const minuteToSecond = time.m * 60;
    totalSeconds += minuteToSecond;
  }

  return totalSeconds + time.s + time.ms / 1000;
};
