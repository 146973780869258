import { GapDomain } from "@/domains/GapDomain";

export interface GapState extends GapDomain {
  isLoading: boolean;
  error: boolean;
  done: boolean;
}

export const gapInitialState: GapState = {
  forecast: 0.0,
  gap: 0.0,
  percentage: 0.0,
  isLoading: false,
  error: false,
  done: false,
};
