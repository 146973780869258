import { useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";

import { useToast } from "@bees-grow-shared/business-components";
import {
  accountSharedService,
  agentCallSharedService,
  ctiSharedService,
  rewardsSharedService,
  useSharedUnit,
} from "@bees-grow-shared/services";
import { useUnit } from "effector-react";

import { useCallSummaryModal } from "@/hooks/useCallSummaryModal/useCallSummaryModal";
import { getCredit } from "@/stores/credit/CreditEvents";
import CreditStore from "@/stores/credit/CreditStore";
import { getGap } from "@/stores/gap/GapEvents";
import GapStore from "@/stores/gap/GapStore";

interface UseSummaryProps {
  errorMessage: string;
  handleRetry: () => void;
  handleToggleModal: () => void;
  isModalOpen: boolean;
}

export function useSummary(): UseSummaryProps {
  const accountService = accountSharedService();
  const agentCallService = agentCallSharedService();
  const ctiService = ctiSharedService();
  const { accountId, vendorId } = useSharedUnit(accountService);
  const {
    ctiEnabled,
    openCall: { error: openCallError },
    startCallDate,
  } = useSharedUnit(agentCallService);
  const { isConnected } = useSharedUnit(ctiService);
  const { formatMessage } = useIntl();
  const toast = useToast();
  const { error: creditError } = useUnit(CreditStore);
  const { error: gapError } = useUnit(GapStore);
  const [errorMessage, setErrorMessage] = useState("");
  const rewardsService = rewardsSharedService();
  const {
    rewards: {
      status: { error: rewardsError },
    },
  } = useSharedUnit(rewardsService);
  const hasShownToastRef = useRef(false);

  const {
    isModalOpen,
    actions: { handleToggleModal },
  } = useCallSummaryModal();

  const isCTIConnected = useMemo(
    () => isConnected && isConnected !== undefined,
    [isConnected],
  );

  useEffect(() => {
    if (ctiEnabled && !isCTIConnected) {
      ctiService.open();
    }
  }, [ctiEnabled, ctiService, isCTIConnected]);

  useEffect(() => {
    if (ctiEnabled && !hasShownToastRef.current) {
      const timer = setTimeout(() => {
        if (isConnected !== undefined && !isConnected && !isModalOpen) {
          toast({
            showCloseButton: true,
            type: "error",
            message: formatMessage({
              id: "CallTimerInfo.ToastMessage.CTI_CONNECTION_FAILED",
            }),
          });
        }
        hasShownToastRef.current = true;
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [isConnected, ctiEnabled, formatMessage, isModalOpen, toast]);

  useEffect(() => {
    const errors = [
      creditError && formatMessage({ id: "SummaryError.CREDIT" }),
      gapError && formatMessage({ id: "SummaryError.GAP" }),
      rewardsError && formatMessage({ id: "SummaryError.REWARDS" }),
    ].filter(Boolean);

    if (openCallError && startCallDate) {
      setErrorMessage(formatMessage({ id: "SummaryError.CALL_SUMMARY" }));
    } else if (errors.length > 1) {
      setErrorMessage(formatMessage({ id: "SummaryError.ALL" }));
    } else if (errors.length === 1) {
      setErrorMessage(errors[0]);
    } else {
      setErrorMessage("");
    }
  }, [
    creditError,
    rewardsError,
    gapError,
    formatMessage,
    openCallError,
    startCallDate,
  ]);

  const handleRetry = () => {
    if (vendorId) {
      if (openCallError && startCallDate) {
        handleToggleModal();
      } else {
        if (creditError) {
          getCredit({ accountId, vendorId });
        }
        if (gapError) {
          getGap({ accountId, vendorId });
        }
        if (rewardsError) {
          rewardsService.getRewards();
        }
      }
    }

    return;
  };

  return { errorMessage, handleRetry, handleToggleModal, isModalOpen };
}
