import { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import {
  CallClassificationEnum,
  PocCallOrigin,
} from "@bees-grow-shared/services";
import { Select } from "@hexa-ui/components";
import { zodResolver } from "@hookform/resolvers/zod";

import { useCallSummaryModal } from "@/hooks/useCallSummaryModal/useCallSummaryModal";

import * as Styles from "./CallSummaryBody.style";
import { CallSummaryActions } from "./components/callSummaryActions/CallSummaryActions";

export function CallSummaryBody() {
  const {
    hasOrder,
    callSummarySchema,
    callLaterOptions,
    cartDetails,
    pocCallOrigin,
    actions: { handleSubmitCallSummary },
  } = useCallSummaryModal();

  const { formatMessage } = useIntl();

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isValid },
    setValue,
  } = useForm({
    resolver: zodResolver(callSummarySchema),
    defaultValues: {
      callClassification: hasOrder
        ? CallClassificationEnum.SUCCESS_ON_CONTACT
        : undefined,
      callLater: undefined,
      callComment: cartDetails,
    },
  });

  const watchCallClassification = watch("callClassification");

  const hasCallLater =
    watchCallClassification === CallClassificationEnum.NO_ANSWER_TRY_AGAIN ||
    watchCallClassification === CallClassificationEnum.CALL_LATER_TODAY;

  const filteredCallClassificationOptions = useMemo(
    () =>
      Object.keys(CallClassificationEnum).filter((classification) => {
        if (pocCallOrigin === PocCallOrigin.SEARCH_BY_POC) {
          return (
            classification !== CallClassificationEnum.CALL_LATER_TODAY &&
            classification !== CallClassificationEnum.NO_ANSWER_TRY_AGAIN
          );
        }

        return true;
      }),
    [pocCallOrigin],
  );

  return (
    <Styles.Form
      onSubmit={handleSubmit(handleSubmitCallSummary)}
      data-testid="CallSummaryBody"
    >
      <Styles.FieldControl>
        <Controller
          name="callClassification"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Select.Root
              disabled={hasOrder}
              label={formatMessage({
                id: "CallSummaryModal.CONTACT_CLASSIFICATION",
              })}
              error={
                errors.callClassification
                  ? formatMessage({
                      id: "CallSummaryModal.REQUIRED_FIELD",
                    })
                  : undefined
              }
              data-testid="CallSummaryBody-callClassification"
              {...field}
              placeholder={formatMessage({
                id: "CallSummaryModal.PLACEHOLDER",
              })}
            >
              {filteredCallClassificationOptions.map((key) => (
                <Select.Option key={key} value={key}>
                  {formatMessage({
                    id: `CallSummaryModal.CallClassification.${key}`,
                  })}
                </Select.Option>
              ))}
            </Select.Root>
          )}
        />
      </Styles.FieldControl>

      <Styles.FieldControl hidden={!hasCallLater}>
        <Controller
          name="callLater"
          control={control}
          rules={{ required: !isValid }}
          render={({ field }) => (
            <Select.Root
              label={formatMessage({
                id: "CallSummaryModal.MAKE_CALL_LATER",
              })}
              placeholder={formatMessage({
                id: "CallSummaryModal.PLACEHOLDER",
              })}
              data-testid="CallSummaryBody-callLater"
              error={
                errors.callLater
                  ? formatMessage({
                      id: "CallSummaryModal.REQUIRED_FIELD",
                    })
                  : undefined
              }
              {...field}
            >
              {callLaterOptions.map((option) => (
                <Select.Option key={option.label} value={String(option.value)}>
                  {formatMessage({
                    id: `CallSummaryModal.${option.label}`,
                  })}
                </Select.Option>
              ))}
            </Select.Root>
          )}
        />
      </Styles.FieldControl>

      <div>
        <Controller
          name="callComment"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Styles.TextArea
              width={"100%"}
              label={formatMessage({ id: "CallSummaryModal.COMMENTS" })}
              placeholder={formatMessage({
                id: "CallSummaryModal.COMMENTS_PLACEHOLDER",
              })}
              height={246}
              {...field}
            />
          )}
        />
      </div>

      <CallSummaryActions handleClick={setValue} />
    </Styles.Form>
  );
}
