import { axiosInstance } from "@bees-grow-shared/services";

import { BasePathParamsRequest } from "@/@types/BasePathParamsRequest";

import { GROW_CREDIT_SERVICE_ENDPOINTS } from "../endpoints";
import { GetCreditResponse } from "./GetCreditResponse";

export async function getCredit(
  params: BasePathParamsRequest,
): Promise<GetCreditResponse> {
  const { data } = await axiosInstance.get<GetCreditResponse>(
    GROW_CREDIT_SERVICE_ENDPOINTS.GET_CREDIT(params),
    { headers: { ...params.headers } },
  );

  return data;
}
