import { useIntl } from "react-intl";

import { ClipboardButton } from "@bees-grow-shared/components";
import { useDefaultAnalyticsData } from "@bees-grow-shared/services";

import segmentAnalytics from "@/config/typewriter";

import { PocData } from "../../types/pocInformationTypes";
import * as Styles from "./GenericInformation.styles";

interface GenericInformationProps {
  basicData: PocData;
}

export default function GenericInformation({
  basicData,
}: GenericInformationProps) {
  const { formatMessage } = useIntl();
  const { title, value, hasClipboard } = basicData;
  const defaultData = useDefaultAnalyticsData();

  const handleClipboardClick = () => {
    /* eslint-disable camelcase */
    segmentAnalytics.buttonClicked({
      button_label: title,
      button_name: "customer_information_copied",
      url: null,
      search_query: null,
      screen_name: "POC Information",
      ...defaultData,
    });
  };

  return (
    <div data-testid={`${GenericInformation.name}`}>
      <Styles.Title
        weight="semibold"
        size="small"
        data-testid={`${GenericInformation.name}-title`}
      >
        {title}
      </Styles.Title>
      <Styles.WrapperValue>
        <Styles.Value data-testid={`${GenericInformation.name}-value`}>
          {value}
        </Styles.Value>
        {hasClipboard && (
          <ClipboardButton
            iconSize="small"
            testId={`${GenericInformation.name}-clipboard`}
            textToCopy={value}
            tooltipCopiedText={formatMessage({ id: "CopyButton.COPIED" })}
            tooltipCopyText={formatMessage({ id: "CopyButton.COPY" })}
            onClick={handleClipboardClick}
          />
        )}
      </Styles.WrapperValue>
    </div>
  );
}
