import CreditBody from "./components/creditBody/CreditBody";
import CreditHeader from "./components/creditHeader/CreditHeader";
import * as Styles from "./Credit.styles";
import { useCredit } from "./hooks/useCredit";

export const Credit = (): JSX.Element => {
  const {
    isDropdownOpen,
    setIsDropdownOpen,
    isLoading,
    containerRef,
    error,
    availableFormatted,
    overdueFormatted,
    totalLimitFormatted,
    creditUsedFormatted,
  } = useCredit();

  return (
    <Styles.Wrapper data-testid={`${Credit.name}`} disabled={error}>
      <Styles.Container
        ref={containerRef}
        border="medium"
        elevated="minimal"
        isDropdownOpen={isDropdownOpen}
      >
        <CreditHeader
          isLoading={isLoading}
          isDropdownOpen={isDropdownOpen}
          setIsDropdownOpen={setIsDropdownOpen}
          hasError={error}
          available={availableFormatted}
        />
        <CreditBody
          data={{
            creditUsed: creditUsedFormatted,
            totalLimit: totalLimitFormatted,
            overdue: overdueFormatted,
          }}
        />
      </Styles.Container>
    </Styles.Wrapper>
  );
};
