import { createStore } from "effector";

import { getCallAttempts, setIsModalOpen } from "./OpenCallEvent";
import { OpenCallState, openCallStoreInitialState } from "./OpenCallState";

const OpenCallStore = createStore(openCallStoreInitialState)
  .on(setIsModalOpen, (state, payload) => ({
    ...state,
    ...payload,
  }))
  .on(getCallAttempts, (state: OpenCallState): OpenCallState => {
    return {
      ...state,
      isLoading: true,
      done: false,
      error: false,
    };
  })
  .on(getCallAttempts.fail, (state: OpenCallState): OpenCallState => {
    return {
      ...state,
      isLoading: false,
      done: true,
      error: true,
    };
  })
  .on(
    getCallAttempts.done,
    (state: OpenCallState, { result }): OpenCallState => {
      return {
        ...state,
        isLoading: false,
        done: true,
        error: false,
        callAttempts: result.callAttempts,
      };
    },
  );

export default OpenCallStore;
