import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";

import {
  accountSharedService,
  useSharedUnit,
} from "@bees-grow-shared/services";

import {
  PocBasicInformation,
  PocInformationDetails,
} from "../types/pocInformationTypes";

interface UsePocInformationProps {
  error: boolean;
  isDropdownOpen: boolean;
  setIsDropdownOpen: (value: boolean | ((prev: boolean) => boolean)) => void;
  pocInformationDetails: PocInformationDetails;
  pocBasicInformation: PocBasicInformation;
  isLoading: boolean;
  containerRef: React.MutableRefObject<HTMLDivElement | null>;
}

export const handleClickOutside = (
  event: { target: object },
  containerRef: React.MutableRefObject<HTMLDivElement | null>,
  setIsDropdownOpen: (value: boolean) => void,
) => {
  if (
    containerRef.current &&
    !containerRef.current.contains(event.target as Node)
  ) {
    setIsDropdownOpen(false);
  } else {
    setIsDropdownOpen(true);
  }
};

export function usePocInformation(): UsePocInformationProps {
  const { formatMessage } = useIntl();
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const accountService = accountSharedService();
  const containerRef = useRef<HTMLDivElement | null>(null);

  const pocInformationData = useSharedUnit(accountService);

  const { error, isLoading } = pocInformationData;

  const outsideClickEvent = (event: MouseEvent) =>
    handleClickOutside(event, containerRef, setIsDropdownOpen);

  useEffect(() => {
    if (isDropdownOpen) {
      document.addEventListener("mousedown", outsideClickEvent);
    }
    return () => {
      document.removeEventListener("mousedown", outsideClickEvent);
    };
  }, [isDropdownOpen]);

  const pocBasicInformation = useMemo(
    () => ({
      pocName: pocInformationData.name,
      ownerName: pocInformationData.owner.fullName,
    }),
    [pocInformationData.owner.fullName, pocInformationData.name],
  );

  const translateTitle = useCallback(
    (title: string): string => formatMessage({ id: `PocInformation.${title}` }),
    [formatMessage],
  );

  const getSalesStructureData = useMemo(
    () => [
      {
        title: translateTitle("DDC"),
        value: `${pocInformationData.sales.subregionCode ? pocInformationData.sales.subregionCode : ""}\n${pocInformationData.sales.subregionName ? pocInformationData.sales.subregionName : ""}`,
        hasClipboard: false,
      },
      {
        title: translateTitle("SALES_REPRESENTATIVE"),
        value: `${pocInformationData.representative.name ? pocInformationData.representative.name : ""}\n${pocInformationData.representative.phone ? pocInformationData.representative.phone : ""}\n${pocInformationData.representative.email ? pocInformationData.representative.email : ""}`,
        hasClipboard: false,
      },
      {
        title: translateTitle("SALES_ORGANIZATION"),
        value: `${pocInformationData.sales.areaName ? pocInformationData.sales.areaName : ""}\n ${pocInformationData.sales.org ? pocInformationData.sales.org : ""}`,
        hasClipboard: false,
      },
      {
        title: translateTitle("SALES_DIRECTION"),
        value: `${pocInformationData.sales.regionCode ? pocInformationData.sales.regionCode : ""}\n ${pocInformationData.sales.regionName ? pocInformationData.sales.regionName : ""}`,
        hasClipboard: false,
      },
      {
        title: translateTitle("SALES_MANAGEMENT"),
        value: `${pocInformationData.sales.subregionCode ? pocInformationData.sales.subregionCode : ""}\n ${pocInformationData.sales.subregionName ? pocInformationData.sales.subregionName : ""}`,
        hasClipboard: false,
      },
      {
        title: translateTitle("SALES_SUPERVISION"),
        value: `${pocInformationData.sales.supervision ?? ""}`,
        hasClipboard: false,
      },
      {
        title: translateTitle("SALES_ZONE"),
        value: `${pocInformationData.sales.zone ? pocInformationData.sales.zone : ""}\n ${pocInformationData.sales.zoneName ? pocInformationData.sales.zoneName : ""}`,
        hasClipboard: false,
      },
      {
        title: translateTitle("CHANNEL"),
        value: pocInformationData.channel ?? "",
        hasClipboard: false,
      },
    ],
    [pocInformationData, translateTitle],
  );

  const getPocInformationData = useMemo(
    () => [
      {
        title: translateTitle("POC_ID"),
        value: pocInformationData.vendorAccountId,
        hasClipboard: true,
      },

      {
        title: translateTitle("TAX_ID"),
        value: pocInformationData.taxId,
        hasClipboard: true,
      },
      {
        title: translateTitle("LIQUOR_LICENSE_NUMBER"),
        value: pocInformationData.liquorLicenseNumber,
        hasClipboard: true,
      },
      {
        title: translateTitle("ACCOUNT_ID"),
        value: pocInformationData.accountId,
        hasClipboard: true,
      },
      {
        title: translateTitle("EMAIL"),
        value: pocInformationData.owner.email,
        hasClipboard: false,
      },
      {
        title: translateTitle("FORMATTED_ADDRESS"),
        value: pocInformationData.formattedAddress,
        hasClipboard: false,
      },
      {
        title: translateTitle("SEGMENT"),
        value: pocInformationData.segment || pocInformationData.segmentName,
        hasClipboard: false,
      },
      {
        title: translateTitle("SUB_SEGMENT"),
        value:
          pocInformationData.subSegment || pocInformationData.subsegmentName,
        hasClipboard: false,
      },
      {
        title: translateTitle("POTENTIAL"),
        value: `${pocInformationData.potential ? pocInformationData.potential : ""}\n${pocInformationData.potentialName ? pocInformationData.potentialName : ""}`,
        hasClipboard: false,
      },
    ],
    [pocInformationData, translateTitle],
  );

  const pocInformationDetails = useMemo(
    () => ({
      pocInformationData: getPocInformationData,
      salesStructureData: getSalesStructureData,
    }),
    [getSalesStructureData, getPocInformationData],
  );

  return {
    error,
    isDropdownOpen,
    setIsDropdownOpen,
    pocInformationDetails,
    pocBasicInformation,
    isLoading,
    containerRef,
  };
}
