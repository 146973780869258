import { CallClassificationEnum } from "@bees-grow-shared/services";
import { z } from "zod";

export const baseCallSummarySchema = z.object({
  callClassification: z.nativeEnum(CallClassificationEnum),
  callLater: z.string().optional(),
  callComment: z.string(),
  submitter: z.string().optional(),
});

export const callSummarySchema = baseCallSummarySchema.superRefine(
  (data, ctx) => {
    if (
      (data.callClassification === CallClassificationEnum.CALL_LATER_TODAY ||
        data.callClassification ===
          CallClassificationEnum.NO_ANSWER_TRY_AGAIN) &&
      !data.callLater
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        params: { callClassification: data.callClassification },
        path: ["callLater"],
      });
    }
  },
);
