import { Tooltip } from "@hexa-ui/components";

import LeverageContent from "./components/leverageContent/LeverageContent";
import { useLeverage } from "./hooks/useLeverage";
import { Container } from "./Leverage.styles";

export const Leverage = (): JSX.Element => {
  const {
    isTruncated,
    textRef,
    isLeverageLoading,
    rewardsData,
    hasLeverageError,
  } = useLeverage();

  return (
    <Container hasTooltip={isTruncated} data-testid="leverage">
      <Tooltip placement="top" text={rewardsData.points}>
        <LeverageContent
          textRef={textRef}
          leverageLoading={isLeverageLoading}
          leverageData={rewardsData}
          leverageError={hasLeverageError}
        />
      </Tooltip>
    </Container>
  );
};
