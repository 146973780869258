import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  gap: 12px;
  grid-template-rows: 1fr 1fr;
  @media (min-width: 1025px) {
    gap: 12px;
    grid-template-rows: max-content;
  }
`;
