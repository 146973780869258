import { getCallAttempts } from "@/services/agentCall/getCallAttempts/getCallAttemptsService";

interface UpsertOpenCallUseCaseProps {
  accountId: string;
  vendorId: string;
}

export const getCallAttemptsUseCase = async ({
  accountId,
  vendorId,
}: UpsertOpenCallUseCaseProps): Promise<{
  callAttempts: number;
}> => {
  const { attempts } = await getCallAttempts({
    path: { accountId },
    headers: { vendorId },
  });

  return {
    callAttempts: attempts,
  };
};
