import { ClubBIcon } from "../clubBIcon/clubBIcon";
import * as Styled from "./LeverageContent.styles";

interface LeverageContentProps {
  leverageData: {
    title: string;
    points: string;
  };
  textRef: React.RefObject<HTMLDivElement>;
  leverageLoading: boolean;
  leverageError: boolean;
}

const LeverageContent = ({
  leverageData,
  leverageLoading,
  leverageError,
  textRef,
}: LeverageContentProps): JSX.Element => {
  const { title, points } = leverageData;
  const titleDefault = "Club B";

  if (leverageLoading) {
    return <Loading />;
  }
  return (
    <Styled.Container
      hasError={leverageError}
      border="medium"
      elevated="minimal"
      data-testid="leverage-content"
    >
      <Styled.ClubName>
        <ClubBIcon hasError={leverageError} />
        <Styled.Title size="small" weight="semibold" hasError={leverageError}>
          {title || titleDefault}
        </Styled.Title>
      </Styled.ClubName>
      <Styled.Value ref={textRef} hasError={leverageError} size="small">
        {points}
      </Styled.Value>
    </Styled.Container>
  );
};

const Loading = () => {
  return (
    <Styled.Container
      hasError={false}
      border="medium"
      elevated="minimal"
      data-testid="leverage-content-loading"
    >
      <Styled.SkeletonTitle>
        <Styled.SkeletonLoader />
      </Styled.SkeletonTitle>

      <Styled.SkeletonValue>
        <Styled.SkeletonLoader />
      </Styled.SkeletonValue>
    </Styled.Container>
  );
};

export default LeverageContent;
