import { tokens } from "@bees-grow-shared/theme";
import styled from "styled-components";

export const Label = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: ${tokens.color.primitive.transparent.black["black-4"]};
  border-radius: ${tokens.measure["border-radius"]["borderradius-full"]};
  padding: ${tokens.measure.space["spacing-2"]};
  color: ${tokens.color.primitive.transparent.black["black-56"]};
  line-height: ${tokens.measure["line-height"]["lineheight-6"]};
  font-weight: ${tokens.fonts["font-weight"].semibold};
  transition: transform 0.5s ease-in-out;
  transform-origin: center;
  text-align: center;

  @media (max-width: 1024px) {
    padding: ${tokens.measure.space["spacing-2"]}
      ${tokens.measure.space["spacing-6"]};
  }
`;
