import { ClubBContainer, ClubBPath } from "./clubBIcon.styles";

const ClubBIcon = ({ hasError }): JSX.Element => (
  <ClubBContainer viewBox="0 0 25 24" fill="none" data-testid={ClubBIcon.name}>
    <ClubBPath
      hasError={hasError}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.2529 3.33977C12.0246 2.88674 12.9754 2.88674 13.7471 3.33977L19.2529 6.57173C20.0246 7.02476 20.5 7.86198 20.5 8.76803V15.232C20.5 16.138 20.0246 16.9752 19.2529 17.4283L13.7471 20.6602C12.9754 21.1133 12.0246 21.1133 11.2529 20.6602L5.74714 17.4283C4.97541 16.9752 4.5 16.138 4.5 15.232V8.76803C4.5 7.86198 4.97541 7.02476 5.74715 6.57173L11.2529 3.33977ZM13.1533 4.38563C12.749 4.14833 12.251 4.14833 11.8467 4.38563L6.34102 7.61759C5.93678 7.85489 5.68776 8.29344 5.68776 8.76803V15.232C5.68776 15.7066 5.93678 16.1451 6.34102 16.3824L11.8467 19.6144C12.251 19.8517 12.749 19.8517 13.1533 19.6144L18.659 16.3824C19.0632 16.1451 19.3122 15.7066 19.3122 15.232V8.76803C19.3122 8.29344 19.0632 7.85489 18.659 7.61759L13.1533 4.38563Z"
      data-testid={`${ClubBIcon.name}-path`}
    />
    <ClubBPath
      d="M14.2485 7.42059H11.337C11.1266 7.42059 10.9548 7.5996 10.9548 7.81791V9.55125H16.2882C16.2882 8.3855 15.3778 7.43369 14.2485 7.42059ZM14.7938 10.6166H11.1094V12.7472H17.3231C17.0783 11.5073 16.0177 10.6166 14.7938 10.6166ZM11.1094 13.8126H17.3231C17.0783 15.0525 16.0177 15.9432 14.7938 15.9432H11.5499C11.4468 15.9432 11.1824 15.9016 11.1094 15.823C11.1094 15.7173 11.1094 15.6787 11.1094 15.5739V13.8126Z"
      data-testid={`${ClubBIcon.name}-path`}
      hasError={hasError}
    />
    <ClubBPath
      d="M8.24522 13.0094C7.47227 13.8345 7.48516 15.1487 8.2667 15.9652C9.04824 16.7773 10.315 16.7904 11.1094 15.987V10.0317L8.24522 13.0094Z"
      data-testid={`${ClubBIcon.name}-path`}
      hasError={hasError}
    />
  </ClubBContainer>
);

export { ClubBIcon };
