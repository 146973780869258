import { useEffect, useRef, useState } from "react";

import {
  accountSharedService,
  agentCallSharedService,
  useCurrencyFormatter,
  useSharedUnit,
} from "@bees-grow-shared/services";
import { useUnit } from "effector-react";

import { getCredit } from "@/stores/credit/CreditEvents";
import CreditStore from "@/stores/credit/CreditStore";

interface UsePocInformationProps {
  isDropdownOpen: boolean;
  setIsDropdownOpen: (value: boolean | ((prev: boolean) => boolean)) => void;
  isLoading: boolean;
  containerRef: React.MutableRefObject<HTMLDivElement | null>;
  error: boolean;
  totalLimitFormatted: string;
  creditUsedFormatted: string;
  availableFormatted: string;
  overdueFormatted: string;
}

export const handleClickOutside = (
  event: { target: object },
  containerRef: React.MutableRefObject<HTMLDivElement | null>,
  setIsDropdownOpen: (value: boolean) => void,
) => {
  if (
    containerRef.current &&
    !containerRef.current.contains(event.target as Node)
  ) {
    setIsDropdownOpen(false);
  } else {
    setIsDropdownOpen(true);
  }
};

export function useCredit(): UsePocInformationProps {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const { isLoading, error, creditUsed, available, overdue, totalLimit } =
    useUnit(CreditStore);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const accountService = accountSharedService();
  const { accountId, vendorId } = useSharedUnit(accountService);

  const agentCallService = agentCallSharedService();
  const { callId } = useSharedUnit(agentCallService);

  const minimumFractionDigits = 2;

  useEffect(() => {
    if (accountId && vendorId) {
      getCredit({ accountId, vendorId });
    }
  }, [accountId, vendorId, callId]);

  const creditUsedFormatted = useCurrencyFormatter(creditUsed, {
    minimumFractionDigits,
  });

  const availableFormatted = useCurrencyFormatter(available, {
    minimumFractionDigits,
  });

  const overdueFormatted = useCurrencyFormatter(overdue, {
    minimumFractionDigits,
  });

  const totalLimitFormatted = useCurrencyFormatter(totalLimit, {
    minimumFractionDigits,
  });

  const outsideClickEvent = (event: MouseEvent) =>
    handleClickOutside(event, containerRef, setIsDropdownOpen);

  useEffect(() => {
    if (isDropdownOpen) {
      document.addEventListener("mousedown", outsideClickEvent);
    }
    return () => {
      document.removeEventListener("mousedown", outsideClickEvent);
    };
  }, [isDropdownOpen]);

  return {
    isDropdownOpen,
    setIsDropdownOpen,
    creditUsedFormatted,
    availableFormatted,
    overdueFormatted,
    totalLimitFormatted,
    isLoading,
    containerRef,
    error,
  };
}
