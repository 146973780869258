import { CreditDomain } from "@/domains/CreditDomain";
import { getCredit } from "@/services/credit/getCredit/GetCreditService";
import { getCreditConvert } from "@/useCases/credit/getCredit/converters/GetCreditConverter";

interface GetCreditProps {
  accountId: string;
  vendorId: string;
}

export const getCreditUseCase = async ({
  accountId,
  vendorId,
}: GetCreditProps): Promise<CreditDomain> => {
  const response = await getCredit({
    path: { accountId },
    headers: { vendorId },
  });

  return getCreditConvert(response);
};
