import React from "react";

import { useCallTimer } from "@/hooks/useCallTimer/useCallTimer";
import { formatTimePart } from "@/utils/formatTimePart/formatTimePart";

import * as Styled from "./FormattedCallSummaryTimer.styles";

function FormattedCallSummaryTimer() {
  const { value: timer } = useCallTimer();

  const labelText = `${formatTimePart(timer.h)}:${formatTimePart(
    timer.m,
  )}:${formatTimePart(timer.s)}`;

  return <Styled.Timer>{labelText}</Styled.Timer>;
}

export default React.memo(FormattedCallSummaryTimer);
