import { tokens } from "@bees-grow-shared/theme";
import { Heading } from "@hexa-ui/components";
import styled from "styled-components";

export const Wrapper = styled.div`
  display: grid;
  padding: ${tokens.measure.space["spacing-4"]} 0;
  grid-template-columns: minmax(18.625rem, 1fr) 0.0625rem minmax(18.625rem, 1fr);
  gap: ${tokens.measure.space["spacing-4"]};
  overflow-y: scroll;
  padding-right: ${tokens.measure.space["spacing-4"]};
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 0.5rem;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--tertiary-color);
    border-radius: 0.7rem;
  }

  ::-webkit-scrollbar-track {
    background-color: var(--primary-color);
    border-radius: 0.7rem;
  }
  scrollbar-width: thin;
  scrollbar-color: ${tokens.color.primitive.solid.neutral["neutral-30"]}
    transparent;

  scroll-margin-left: ${tokens.measure.space["spacing-4"]};

  -ms-overflow-style: -ms-autohiding-scrollbar;

  ::-webkit-scrollbar-button {
    display: none;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const BodyTitle = styled(Heading)`
  margin-bottom: ${tokens.measure.space["spacing-6"]};
  line-height: 1.25rem;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.measure.space["spacing-4"]};
`;
