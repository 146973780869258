import { constructEndpoint } from "@bees-grow-shared/services";

import { BasePathParamsRequest } from "@/@types/BasePathParamsRequest";
import { MICROSERVICES } from "@/services/MicroServices";

enum ENDPOINTS {
  CREDIT = "/accounts/{accountId}/credits",
}

export const GROW_CREDIT_SERVICE_ENDPOINTS = {
  GET_CREDIT: ({ path }: BasePathParamsRequest) =>
    constructEndpoint({
      service: MICROSERVICES.BEES_GROW_BFF,
      endpoint: ENDPOINTS.CREDIT,
      pathParams: { ...path },
    }),
};
