import { tokens } from "@bees-grow-shared/theme";
import { Heading } from "@hexa-ui/components";
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${tokens.measure.space["spacing-4"]};
  gap: ${tokens.measure.space["spacing-4"]};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const BodyTitle = styled(Heading)`
  margin-bottom: ${tokens.measure.space["spacing-4"]};
  line-height: 1.25rem;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.measure.space["spacing-2"]};
`;
