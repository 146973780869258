import { GapDomain } from "@/domains/GapDomain";
import { GetGapResponse } from "@/services/gap/getGap/GetGapResponse";

export function getGapConvert(data: GetGapResponse): GapDomain {
  if (!data) {
    return {} as GapDomain;
  }
  return {
    forecast: data.forecast,
    gap: data.gap,
    percentage: data.percentage,
  } as GapDomain;
}
