import { axiosInstance } from "@bees-grow-shared/services";

import { GROW_OPEN_CALL_SERVICE_ENDPOINTS } from "../endpoints";
import { GetCallAttemptsParams } from "./model/getCallAttemptsRequest";

interface GetCallAttemptsResponse {
  attempts: number;
}

export async function getCallAttempts(
  params: GetCallAttemptsParams,
): Promise<GetCallAttemptsResponse> {
  const { data } = await axiosInstance.get<GetCallAttemptsResponse>(
    GROW_OPEN_CALL_SERVICE_ENDPOINTS.CALL_ATTEMPTS(params),
    { headers: { ...params.headers } },
  );

  return data;
}
