import { useCallback } from "react";

import {
  ClassificationEnum,
  phoneRankSharedService,
  PhoneRequestType,
  PhoneResponseType,
  PhoneTypeEnum,
  ScoreEnum,
} from "@bees-grow-shared/services";

import { NEW_CONTACT_BASE_NAME } from "@/constant/newContactBaseName";

export function useHandleCallClassification(
  phones: PhoneResponseType[],
  phoneAutoDialer: string,
  accountId: string,
  vendorId: string,
) {
  const phoneRankService = phoneRankSharedService();

  const createNewContact = useCallback(
    (phones: PhoneResponseType[], phoneNumber: string): PhoneRequestType[] => {
      const suffix = phones.filter(({ phoneContact }) =>
        phoneContact?.startsWith(NEW_CONTACT_BASE_NAME),
      ).length;
      const newName = `${NEW_CONTACT_BASE_NAME}${suffix > 0 ? ` ${suffix}` : ""}`;

      return [
        {
          phoneNumber,
          classification: ClassificationEnum.INPUTTED,
          contactName: newName,
          phoneType: PhoneTypeEnum.INPUTTED_PHONE,
        },
      ];
    },
    [],
  );

  const handleCallClassification = useCallback(async () => {
    const foundPhone = phones.find(
      (phone: PhoneResponseType) => phone.phoneNumber === phoneAutoDialer,
    );

    if (!foundPhone) {
      const newContact = createNewContact(phones, phoneAutoDialer);

      await phoneRankService.postPhoneRank({
        params: { accountId, vendorId },
        phones: newContact,
      });

      return;
    }

    const isInactive = foundPhone.score === ScoreEnum.INACTIVE;

    const updatedPhone: PhoneRequestType = {
      contactName: foundPhone.phoneContact,
      phoneNumber: phoneAutoDialer,
      classification: isInactive
        ? ClassificationEnum.ACTIVATE
        : ClassificationEnum.GOOD,
      phoneType: PhoneTypeEnum.INPUTTED_PHONE,
    };

    await phoneRankService.updatePhoneRank({
      params: { accountId, vendorId, phoneNumber: phoneAutoDialer },
      phone: updatedPhone,
    });
  }, [
    accountId,
    vendorId,
    phoneAutoDialer,
    phoneRankService,
    phones,
    createNewContact,
  ]);

  return handleCallClassification;
}
