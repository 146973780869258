import { CreditDomain } from "@/domains/CreditDomain";
import { GetCreditResponse } from "@/services/credit/getCredit/GetCreditResponse";

export function getCreditConvert(data: GetCreditResponse): CreditDomain {
  if (!data) {
    return {} as CreditDomain;
  }
  return {
    available: data.available,
    creditUsed: data.creditUsed,
    overdue: data.overdue,
    totalLimit: data.totalLimit,
  } as CreditDomain;
}
