import { useIntl } from "react-intl";

import PocInformationBody from "./components/pocInformationBody/PocInformationBody";
import PocInformationHeader from "./components/pocInformationHeader/PocInformationHeader";
import { usePocInformation } from "./hooks/usePocInformation";
import * as Styles from "./PocInformation.styles";

export const PocInformation = (): JSX.Element => {
  const {
    error,
    isDropdownOpen,
    setIsDropdownOpen,
    pocInformationDetails,
    pocBasicInformation,
    isLoading,
    containerRef,
  } = usePocInformation();

  const { formatMessage } = useIntl();

  const elementError = (
    <Styles.ContainerDisabled data-testid="container-disabled">
      <Styles.TextHyphenContainerDisabled>-</Styles.TextHyphenContainerDisabled>
      <Styles.TextContainerDisabled>
        {formatMessage({ id: "PocInformation.OWNER_NAME" })}
      </Styles.TextContainerDisabled>
      <Styles.TextHyphenContainerDisabled>-</Styles.TextHyphenContainerDisabled>
    </Styles.ContainerDisabled>
  );

  return (
    <Styles.Wrapper data-testid={`${PocInformation.name}`}>
      <Styles.Container
        ref={containerRef}
        border="medium"
        elevated="minimal"
        isDropdownOpen={isDropdownOpen}
        isError={error}
      >
        {error ? (
          elementError
        ) : (
          <>
            <PocInformationHeader
              isLoading={isLoading}
              isDropdownOpen={isDropdownOpen}
              setIsDropdownOpen={setIsDropdownOpen}
              pocBasicInformation={pocBasicInformation}
            />
            <PocInformationBody pocDetails={pocInformationDetails} />
          </>
        )}
      </Styles.Container>
    </Styles.Wrapper>
  );
};
