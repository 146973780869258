import { useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";

import {
  accountSharedService,
  rewardsSharedService,
  useNumberFormatter,
  useSharedUnit,
} from "@bees-grow-shared/services";

interface UseLeverageProps {
  rewardsData: {
    title: string;
    points: string;
    dateRegistered: string;
  };
  isLeverageLoading: boolean;
  hasLeverageError: boolean;
  isTruncated: boolean;
  textRef: React.RefObject<HTMLDivElement>;
}

export function isTextOverflowing(textElement: HTMLElement | null) {
  if (!textElement) return false;
  const isOverflowing = textElement.scrollWidth > textElement.offsetWidth;
  return isOverflowing;
}

export const useLeverage = (): UseLeverageProps => {
  const rewardsService = rewardsSharedService();
  const {
    rewards: {
      tier,
      balance,
      dateRegistered,
      status: {
        isLoading: isLeverageLoading,
        error: hasLeverageError,
        done: isLeverageDone,
      },
    },
  } = useSharedUnit(rewardsService);

  const accountService = accountSharedService();
  const { accountId, vendorId } = useSharedUnit(accountService);

  const [isTruncated, setIsTruncated] = useState(false);
  const textRef = useRef<HTMLDivElement | null>(null);
  const { formatMessage } = useIntl();
  const formattedPoints = useNumberFormatter(balance);

  const formatPointsText = useMemo(() => {
    if (!tier) {
      return formatMessage({
        id: "CreditAndPoints.Leverage.ClubB.NOT_ENROLED",
      });
    }
    return `${formattedPoints} ${formatMessage({
      id: "CreditAndPoints.Leverage.ClubB.POINTS",
    })}`;
  }, [tier, formattedPoints, formatMessage]);

  const removeSpecialCharacters = useMemo(
    () => () => tier?.replace(/[^\w\s]|_/gi, " ").toLowerCase(),
    [tier],
  );

  useEffect(() => {
    if (textRef.current) {
      setIsTruncated(isTextOverflowing(textRef.current));
    }
  }, [formatPointsText]);

  useEffect(() => {
    if (!isLeverageDone && !isLeverageLoading && accountId && vendorId) {
      rewardsService.getRewards();
    }
  }, [isLeverageDone, isLeverageLoading, rewardsService, accountId, vendorId]);

  const rewardsData = {
    title: removeSpecialCharacters(),
    points: formatPointsText,
    dateRegistered,
  };

  return {
    isLeverageLoading,
    hasLeverageError,
    rewardsData,
    isTruncated,
    textRef,
  };
};
