import { tokens } from "@bees-grow-shared/theme";
import {
  Card,
  IconButton as IconButtonHexa,
  Paragraph,
} from "@hexa-ui/components";
import styled from "styled-components";

export const Container = styled(Card)<{ gap?: string }>`
  display: flex;
  flex-direction: row;
  gap: ${tokens.measure.space["spacing-2"]};
  padding: ${tokens.measure.space["spacing-4"]};

  & > div.column {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: space-between;
  }

  & > div.column > div.row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: ${tokens.measure.space["spacing-2"]};
  }

  & > div.column > div.row.info {
    @media (max-width: 1024px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 0px;
    }
  }
`;

export const Label = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: ${tokens.color.primitive.transparent.black["black-4"]};
  border-radius: ${tokens.measure["border-radius"]["borderradius-full"]};
  padding: ${tokens.measure.space["spacing-2"]};
  color: ${tokens.color.primitive.transparent.black["black-56"]};
  line-height: ${tokens.measure["line-height"]["lineheight-6"]};
  font-weight: ${tokens.fonts["font-weight"].semibold};
  transition: transform 0.5s ease-in-out;
  transform-origin: center;
  text-align: center;

  @media (max-width: 1024px) {
    padding: ${tokens.measure.space["spacing-2"]}
      ${tokens.measure.space["spacing-6"]};
  }
`;

export const Text = styled(Paragraph)<{
  bold?: string;
}>`
  font-size: ${tokens.measure["font-size"]["fontsize-2"]};
  font-weight: ${(p) =>
    p.bold
      ? tokens.fonts["font-weight"].semibold
      : tokens.fonts["font-weight"].normal};

  color: ${tokens.color.primitive.solid.neutral["neutral-100"]};
  line-height: ${tokens.measure["line-height"]["lineheight-5"]};

  &[color="red"] {
    color: ${tokens.color.primitive.solid.red["red-80"]};
  }

  &[color="gray"] {
    color: ${tokens.color.primitive.transparent.black["black-56"]};
  }

  &[color="green"] {
    color: ${tokens.color.primitive.solid.green["green-80"]};
  }
`;

export const Skeleton = styled.div`
  display: flex;
  width: 100%;
  gap: ${tokens.measure.space["spacing-2"]};
  &:nth-child(2) {
    justify-content: flex-end;
  }
`;

export const IconButton = styled(IconButtonHexa)<{ phone?: string }>`
  & > svg {
    transition: transform 0.5s ease-in-out;
    transform-origin: center;
  }

  &:hover > svg {
    color: ${tokens.color.component.icon.button.primary.default};
  }

  &[phone="off"] {
    background-color: ${tokens.color.primitive.solid.green["green-70"]};
  }

  &[phone="on"] {
    background-color: ${tokens.color.primitive.solid.red["red-70"]};
    & > svg {
      transform: rotate(0.375turn);
    }
  }
`;

export const AttemptsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${tokens.measure.space["spacing-1"]};
`;
