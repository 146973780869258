import { axiosInstance } from "@bees-grow-shared/services";

import { BasePathParamsRequest } from "@/@types/BasePathParamsRequest";

import { GROW_GAP_SERVICE_ENDPOINTS } from "../endpoints";
import { GetGapResponse } from "./GetGapResponse";

export async function getGap(
  params: BasePathParamsRequest,
): Promise<GetGapResponse> {
  const { data } = await axiosInstance.get<GetGapResponse>(
    GROW_GAP_SERVICE_ENDPOINTS.GET_GAP(params),
    { headers: { ...params.headers } },
  );

  return data;
}
