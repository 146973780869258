import { CreditDomain } from "@/domains/CreditDomain";

export interface CreditState extends CreditDomain {
  isLoading: boolean;
  error: boolean;
  done: boolean;
}

export const creditInitialState: CreditState = {
  available: 0.0,
  creditUsed: 0.0,
  overdue: 0.0,
  totalLimit: 0.0,
  isLoading: false,
  error: false,
  done: false,
};
