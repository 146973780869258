import { tokens } from "@bees-grow-shared/theme";
import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  gap: ${tokens.measure.space["spacing-6"]};
  margin-bottom: ${tokens.measure.space["spacing-6"]};

  & > p {
    white-space: nowrap;

    display: flex;
    gap: ${tokens.measure.space["spacing-2"]};
    align-items: center;

    color: ${tokens.color.foundation.txt.default};
    font-size: ${tokens.measure["font-size"]["fontsize-2"]};
    line-height: ${tokens.measure["line-height"]["lineheight-5"]};

    & > svg {
      fill: ${tokens.color.foundation.txt.disabled};
    }
  }
`;

export const Timer = styled.span`
  color: #009900;
`;

export const Status = styled.span.withConfig({
  shouldForwardProp: (prop) => prop !== "isCTIConnected",
})<{ isCTIConnected: boolean }>`
  color: ${(props) => (props.isCTIConnected ? "#009900" : "#E82727")};
`;

export const Attempts = styled.span`
  color: #e82727;
`;
