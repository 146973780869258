import { tokens } from "@bees-grow-shared/theme";
import styled from "styled-components";

export const ClubBContainer = styled.svg`
  width: 1.5rem;
  height: 1.5rem;
`;

export const ClubBPath = styled.path<{ hasError: boolean }>`
  fill: ${tokens.color.component.icon.avatar.default};
  fill-opacity: ${({ hasError }) =>
    hasError
      ? `${tokens.measure.opacity.medium}`
      : `${tokens.measure.opacity.opaque}`};
`;
