import { createStore } from "effector";

import { getCredit } from "./CreditEvents";
import { creditInitialState, CreditState } from "./CreditState";

const CreditStore = createStore(creditInitialState)
  .on(getCredit, (state: CreditState): CreditState => {
    return {
      ...state,
      isLoading: true,
      done: false,
      error: false,
    };
  })
  .on(
    getCredit.fail,
    (state: CreditState): CreditState => ({
      ...state,
      isLoading: false,
      done: true,
      error: true,
    }),
  )
  .on(getCredit.done, (state: CreditState, { result }): CreditState => {
    return {
      ...state,
      ...result,
      isLoading: false,
      done: true,
      error: false,
    };
  });

export default CreditStore;
