import { axiosInstance } from "@bees-grow-shared/services";

import { GROW_OPEN_CALL_SERVICE_ENDPOINTS } from "../endpoints";
import {
  UpsertOpenCallParams,
  UpsertOpenCallRequestBody,
} from "./model/UpsertOpenCallRequest";

export async function upsertOpenCall(
  params: UpsertOpenCallParams,
  body: UpsertOpenCallRequestBody,
): Promise<void> {
  const { data } = await axiosInstance.patch<void>(
    GROW_OPEN_CALL_SERVICE_ENDPOINTS.OPEN_CALL(params),
    body,
    { headers: { ...params.headers } },
  );

  return data;
}
