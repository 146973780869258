import { useIntl } from "react-intl";

import {
  agentCallSharedService,
  PocCallOrigin,
  useSharedUnit,
} from "@bees-grow-shared/services";
import { Button, Divider, Heading, Modal } from "@hexa-ui/components";

import * as Styles from "./CallSummaryActions.style";

export function CallSummaryActions({
  handleClick,
}: {
  handleClick: (key: string, value: string) => void;
}) {
  const { formatMessage } = useIntl();
  const { pocCallOrigin } = useSharedUnit(agentCallSharedService());

  return (
    <Styles.Container data-testid="CallSummaryActions">
      <Styles.Description>
        <Heading size="H4">
          {formatMessage({ id: "CallSummaryModal.NEXT_STEPS" })}
        </Heading>
        <Divider />
        <Styles.Paragraph size="small">
          {formatMessage({ id: "CallSummaryModal.NEXT_STEPS_DESCRIPTION" })}
        </Styles.Paragraph>
      </Styles.Description>

      <Styles.SubmitButtonContainer>
        {pocCallOrigin === PocCallOrigin.SEARCH_BY_POC ? (
          <Modal.Action>
            <Button
              size="medium"
              variant="primary"
              type="submit"
              data-testid="CallSummaryModal-action"
              onClick={() => {
                handleClick("submitter", "GO_HOME");
              }}
            >
              {formatMessage({ id: "CallSummaryModal.GO_HOME" })}
            </Button>
          </Modal.Action>
        ) : (
          <>
            <Modal.Action>
              <Button
                variant="secondary"
                type="submit"
                onClick={() => {
                  handleClick("submitter", "TAKE_BREAK");
                }}
              >
                {formatMessage({ id: "CallSummaryModal.TAKE_BREAK" })}
              </Button>
            </Modal.Action>
            <Modal.Action>
              <Button
                type="submit"
                data-testid="CallSummaryModal-action"
                onClick={() => {
                  handleClick("submitter", "CALL_NEXT_POC");
                }}
              >
                {formatMessage({ id: "CallSummaryModal.CALL_NEXT_POC" })}
              </Button>
            </Modal.Action>
          </>
        )}
      </Styles.SubmitButtonContainer>
    </Styles.Container>
  );
}
