import { tokens } from "@bees-grow-shared/theme";
import { TextArea as TextAreaHexa } from "@hexa-ui/components";
import styled from "styled-components";

export const TextArea = styled(TextAreaHexa)`
  height: 33vh;
  resize: none;
`;

export const Form = styled.form`
  width: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
  gap: ${tokens.measure.space["spacing-4"]};

  & > div label {
    color: ${tokens.color.foundation.txt.default};
    line-height: ${tokens.measure["line-height"]["lineheight-5"]};
  }
`;

export const FieldControl = styled.div<{ hidden?: boolean }>`
  display: flex;
  flex-direction: column;

  & > button[class*="error-true"] {
    margin-bottom: ${tokens.measure.space["spacing-1"]};
    outline-color: ${tokens.color.foundation.txt.error};
  }

  & > div:has(svg) {
    font-size: ${tokens.measure["font-size"]["fontsize-1"]};
    color: ${tokens.color.foundation.txt.error};
    order: 1;
    padding: 0;
  }

  ${(p) => p.hidden && `display:none`}
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.measure.space["spacing-2"]};
  margin-top: 10px;

  & > h4 {
    color: ${tokens.color.foundation.txt.default};
  }
`;
