import { tokens } from "@bees-grow-shared/theme";
import {
  Heading as HeadingHexa,
  IconButton as IconButtonHexa,
} from "@hexa-ui/components";
import styled from "styled-components";

export const ModalWrapper = styled.div`
  position: absolute;
  height: 100%;
  overflow: hidden;

  & div[role="alertdialog"] {
    & > div {
      padding: 1.5rem;
      width: 33.75rem;
      margin: 0;
    }
  }
`;

export const IconButton = styled(IconButtonHexa)`
  background-color: ${tokens.color.component.bg.button.tertiary.default};
  border: none;

  &:hover {
    box-shadow: none;
    background-color: ${tokens.color.flavor.brand.grow["grow-70"]};
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: ${tokens.measure.space["spacing-4"]};
`;

export const Heading = styled(HeadingHexa)`
  font-size: ${tokens.measure["font-size"]["fontsize-6"]};
  line-height: ${tokens.measure["line-height"]["lineheight-7"]};
  color: ${tokens.color.foundation.txt.default};
`;
