import { Credit } from "./components/credit/Credit";
import { Leverage } from "./components/leverage/Leverage";
import { Container } from "./CreditAndPoints.styles";

export const CreditAndPoints = (): JSX.Element => {
  return (
    <Container data-testid={`${CreditAndPoints.name}`}>
      <Leverage />
      <Credit />
    </Container>
  );
};
