import React from "react";
import { useIntl } from "react-intl";

import { useCallTimer } from "@/hooks/useCallTimer/useCallTimer";
import { formatTimePart } from "@/utils/formatTimePart/formatTimePart";

import * as Styled from "./FormattedCallTimer.styles";

interface FormattedCallTimerProps {
  isOnCall: boolean;
}

function FormattedCallTimer({ isOnCall }: FormattedCallTimerProps) {
  const { value: timer } = useCallTimer();
  const { formatMessage } = useIntl();

  const labelText = isOnCall
    ? `${formatTimePart(timer.h)}:${formatTimePart(
        timer.m,
      )}:${formatTimePart(timer.s)}`
    : formatMessage({ id: "CallTimerInfo.START_CALL" });

  return (
    <Styled.Label data-testid={`call-timer-label`}>{labelText}</Styled.Label>
  );
}

export default React.memo(FormattedCallTimer);
