import { GenericData } from "../../types/creditTypes";
import * as Styles from "./CreditContent.styles";

interface CreditContentProps {
  basicData: GenericData;
  "data-testid"?: string;
}

export default function CreditContent({
  basicData,
  ...props
}: Readonly<CreditContentProps>) {
  const { title, value } = basicData;

  return (
    <Styles.Wrapper data-testid={`${CreditContent.name}`}>
      <Styles.Title
        weight="semibold"
        size="small"
        data-testid={
          props["data-testid"]
            ? `${props["data-testid"]}-title`
            : `${CreditContent.name}-title`
        }
      >
        {title}
      </Styles.Title>

      <Styles.Value
        data-testid={
          props["data-testid"]
            ? `${props["data-testid"]}-value`
            : `${CreditContent.name}-value`
        }
      >
        {value}
      </Styles.Value>
    </Styles.Wrapper>
  );
}
