import MessageMap from "../i18n.d";

const es419: MessageMap = {
  SummaryError: {
    ALL: "No se puede cargar algunas informaciones en esta página.",
    GAP: "No se puede cargar la información de la topline gap.",
    REWARDS: "No se pueden cargar las información del Club B.",
    CREDIT: "No se pueden cargar las información de línea de compra.",
    RETRY: "Intentar de nuevo",
    CALL_SUMMARY: "No se puede finalizar la llamada.",
  },
  CopyButton: {
    COPY: "Copiar",
    COPIED: "Copiado",
  },
  TopLineGap: {
    TITLE: "Oportunidad de gap",
    GOAL_TEXT: "Meta",
    GAP_TEXT: "Gap",
    ERROR_MESSAGE: "No se puede cargar la información de topline gap.",
  },
  PocInformation: {
    OWNER_NAME: "Propietario",
    SHOW_LESS: "Menos detalles",
    SHOW_MORE: "Mostrar más",
    POC_INFORMATION_TITLE: "Información del cliente",
    SALES_STRUCTURE_TITLE: "Estructura de ventas",
    POC_ID: "Vendor account ID",
    ERP_ID: "ERP ID",
    TAX_ID: "Tax ID",
    LIQUOR_LICENSE_NUMBER: "Licencia alcohólica",
    ACCOUNT_ID: "Account ID",
    EMAIL: "Email",
    FORMATTED_ADDRESS: "Dirección",
    SEGMENT: "Segmento",
    SUBSEGMENT: "Subsegmento",
    POTENTIAL: "Potencial",
    DDC: "DDC",
    SALES_REPRESENTATIVE: "Representante de ventas",
    SALES_ORGANIZATION: "Organización de ventas",
    SALES_DIRECTION: "Dirección de ventas",
    SALES_MANAGEMENT: "Gerencia de ventas",
    SALES_SUPERVISION: "Supervisión de ventas",
    SALES_ZONE: "Zona de ventas",
    CHANNEL: "Canal",
  },
  CreditAndPoints: {
    Leverage: {
      ClubB: {
        POINTS: "puntos",
        NOT_ENROLED: "No inscrito",
        Error: {
          DESCRIPTION: "No se puede cargar la información del Club B.",
          RETRY_TITLE: "Tentar novamente",
        },
      },
    },
    Credit: {
      TITLE: "Línea de compra disponible",
      TOTAL_LIMIT: "Límite total",
      CREDIT_USE: "Valor en uso",
      CUMULATIVE_DISCOUNT: "Descuento acumulativo",
      EXPIRATION_DATE: "Fecha de vencimiento",
      ERROR_MESSAGE: "No se pueden cargar las información de crédito.",
      OVERDUE: "Vencido",
      PAYMENT: {
        INFORMATION: "Información de pago",
        METHOD: "Método de pago",
        PERIOD: "Período de pago",
      },
      REFERENCE_ID: "ID de referencia",
    },
  },
  CallTimerInfo: {
    START_CALL: "Iniciar llamada",
    ATTEMPTS: "Intentos",
    ONLINE: "Online",
    OFFLINE: "Offline",
    ToastMessage: {
      CTI_CONNECTION_FAILED: "No se pudo conectar con el CTI.",
    },
  },
  CallSummaryModal: {
    CALL_SUMMARY: "Resumen de llamada",
    CALL_DURATION: "Duración:",
    NEXT_STEPS: "Próximos pasos",
    NEXT_STEPS_DESCRIPTION: "Ambas opciones registrarán tus respuestas.",
    CALL_ATTEMPTS: "Intentos: ",
    CONTACT_CLASSIFICATION: "Clasificación de contacto",
    MAKE_CALL_LATER: "Próximo intento de llamada",
    UNABLE_FINISH_CALL: "No se puede finalizar la llamada.",
    REQUIRED_FIELD: "Este campo es obligatorio.",
    TAKE_BREAK: "Tómese un descanso",
    GO_HOME: "Ir a menu principal",
    CALL_NEXT_POC: "Llamar el siguiente PDV",
    COMMENTS: "Comentarios",
    COMMENTS_PLACEHOLDER: "Ingrese comentarios aquí...",
    CONNECTED: "Online",
    DISCONNECTED: "Offline",
    PLACEHOLDER: "Seleccione una opción",
    CallClassification: {
      SUCCESS_ON_CONTACT: "Contacto exitoso",
      NO_ANSWER_DO_NOT_CALL_AGAIN: "No contactado - No volver a llamar",
      NO_ANSWER_TRY_AGAIN: "No contactado - Intentar de nuevo",
      RESPONSIBLE_NOT_AVAILABLE: "Encargado no disponible",
      INCORRECT_PHONE_NUMBER: "Teléfono incorrecto",
      POC_CLOSED_DUPLICATE: "PDV cerrado o duplicado",
      CALL_LATER_TODAY: "Llamar más tarde",
    },
    CallTypification: {
      "15_MINUTES": "15 minutos",
      "30_MINUTES": "30 minutos",
      "1_HOUR": "1 hora",
      "1_HOUR_AND_30_MINUTES": "1 hora y 30 minutos",
      "2_HOURS": "2 horas",
      "2_HOUR_AND_30_MINUTES": "2 horas y 30 minutos",
      "3_HOURS": "3 horas",
      "3_HOUR_AND_30_MINUTES": "3 horas y 30 minutos",
      "4_HOURS": "4 horas",
      "4_HOUR_AND_30_MINUTES": "4 horas y 30 minutos",
      "5_HOURS": "5 horas",
      "5_HOUR_AND_30_MINUTES": "5 horas y 30 minutos",
      "6_HOURS": "6 horas",
      "6_HOUR_AND_30_MINUTES": "6 horas y 30 minutos",
      "7_HOURS": "7 horas",
      "7_HOUR_AND_30_MINUTES": "7 horas y 30 minutos",
      "8_HOURS": "8 horas",
    },
  },
};

export default es419;
