import { createEffect, createEvent } from "effector";

import { getCallAttemptsUseCase } from "@/useCases/agentCall/getCallAttempts/getCallAttemptsUseCase";

import { OpenCallState } from "./OpenCallState";

interface GetCallCounterProps {
  accountId: string;
  vendorId: string;
}

export const setIsModalOpen =
  createEvent<Partial<OpenCallState>>("setIsModalOpen");

export const getCallAttempts = createEffect({
  async handler(props: GetCallCounterProps): Promise<{
    callAttempts: number;
  }> {
    return await getCallAttemptsUseCase(props);
  },
});
