import MessageMap from "../i18n.d";

const ptBR: MessageMap = {
  SummaryError: {
    ALL: "Não foi possível carregar algumas informações nesta página.",
    GAP: "Não é possível carregar as informações de topline gap.",
    REWARDS: "Não é possível carregar as informações do Club B.",
    CREDIT: "Não é possível carregar as informações de crédito.",
    RETRY: "Tentar novamente",
    CALL_SUMMARY: "Não é possível finalizar a chamada.",
  },
  CopyButton: {
    COPY: "Copiar",
    COPIED: "Copiado",
  },
  TopLineGap: {
    TITLE: "Gap de oportunidade",
    GOAL_TEXT: "Meta",
    GAP_TEXT: "Gap",
    ERROR_MESSAGE: "Não foi possível carregar as informações de topline gap.",
  },
  PocInformation: {
    SHOW_LESS: "Menos detalhes",
    SHOW_MORE: "Mais detalhes",
    OWNER_NAME: "Proprietário",
    POC_INFORMATION_TITLE: "Informação do cliente",
    SALES_STRUCTURE_TITLE: "Estrutura de vendas",
    POC_ID: "Vendor account ID",
    ERP_ID: "ERP ID",
    TAX_ID: "Tax ID",
    LIQUOR_LICENSE_NUMBER: "Licença alcoólica",
    ACCOUNT_ID: "Account ID",
    EMAIL: "Email",
    FORMATTED_ADDRESS: "Endereço",
    SEGMENT: "Segmento",
    SUB_SEGMENT: "Sub-segmento",
    POTENTIAL: "Potencial",
    DDC: "CDD",
    SALES_REPRESENTATIVE: "Representante de vendas",
    SALES_ORGANIZATION: "Organização de vendas",
    SALES_DIRECTION: "Direção de vendas",
    SALES_MANAGEMENT: "Gerencia de vendas",
    SALES_SUPERVISION: "Supervisão de vendas",
    SALES_ZONE: "Zona de vendas",
    CHANNEL: "Canal",
  },
  CreditAndPoints: {
    Leverage: {
      ClubB: {
        POINTS: "pontos",
        NOT_ENROLED: "Não inscrito",
        Error: {
          DESCRIPTION: "Não foi possível carregar as informações do Club B.",
          RETRY_TITLE: "Tente novamente",
        },
      },
    },
    Credit: {
      TITLE: "Crédito disponível",
      TOTAL_LIMIT: "Limite total",
      CREDIT_USE: "Crédito utilizado",
      CUMULATIVE_DISCOUNT: "Desconto acumulativo",
      EXPIRATION_DATE: "Data de vencimento",
      ERROR_MESSAGE: "Não é possível carregar as informações de crédito.",
      OVERDUE: "Vencido",
      PAYMENT: {
        INFORMATION: "Informações de pagamento",
        METHOD: "Método de pagamento",
        PERIOD: "Período de pagamento",
      },
      REFERENCE_ID: "ID de referência",
    },
  },
  CallTimerInfo: {
    START_CALL: "Iniciar chamada",
    ATTEMPTS: "Tentativas",
    ONLINE: "Online",
    OFFLINE: "Offline",
    ToastMessage: {
      CTI_CONNECTION_FAILED: "Não foi possível conectar com o CTI.",
    },
  },
  CallSummaryModal: {
    CALL_SUMMARY: "Resumo da chamada",
    CALL_DURATION: "Duração: ",
    NEXT_STEPS: "Próximos passos",
    NEXT_STEPS_DESCRIPTION: "Ambas as opções registrarão suas respostas.",
    CALL_ATTEMPTS: "Tentativas: ",
    CONTACT_CLASSIFICATION: "Classificação do contato",
    MAKE_CALL_LATER: "Próxima tentativa de chamada",
    UNABLE_FINISH_CALL: "Não é possível finalizar a chamada.",
    REQUIRED_FIELD: "Este campo é obrigatório.",
    TAKE_BREAK: "Fazer uma pausa",
    GO_HOME: "Ir para tela inicial",
    CALL_NEXT_POC: "Ligar para outro PDV",
    COMMENTS: "Comentários",
    COMMENTS_PLACEHOLDER: "Digite os comentários aqui...",
    CONNECTED: "Online",
    DISCONNECTED: "Offline",
    PLACEHOLDER: "Selecione uma opção",
    CallClassification: {
      SUCCESS_ON_CONTACT: "Sucesso no contato",
      NO_ANSWER_DO_NOT_CALL_AGAIN: "Chamada não atendida - Não ligar novamente",
      NO_ANSWER_TRY_AGAIN: "Chamada não atendida - Tentar novamente",
      RESPONSIBLE_NOT_AVAILABLE: "Responsável indisponível",
      INCORRECT_PHONE_NUMBER: "Telefone incorreto",
      POC_CLOSED_DUPLICATE: "PDV fechado ou duplicado",
      CALL_LATER_TODAY: "Ligar novamente mais tarde",
    },
    CallTypification: {
      "15_MINUTES": "15 minutos",
      "30_MINUTES": "30 minutos",
      "1_HOUR": "1 hora",
      "1_HOUR_AND_30_MINUTES": "1 hora e 30 minutos",
      "2_HOURS": "2 horas",
      "2_HOUR_AND_30_MINUTES": "2 horas e 30 minutos",
      "3_HOURS": "3 horas",
      "3_HOUR_AND_30_MINUTES": "3 horas e 30 minutos",
      "4_HOURS": "4 horas",
      "4_HOUR_AND_30_MINUTES": "4 horas e 30 minutos",
      "5_HOURS": "5 horas",
      "5_HOUR_AND_30_MINUTES": "5 horas e 30 minutos",
      "6_HOURS": "6 horas",
      "6_HOUR_AND_30_MINUTES": "6 horas e 30 minutos",
      "7_HOURS": "7 horas",
      "7_HOUR_AND_30_MINUTES": "7 horas e 30 minutos",
      "8_HOURS": "8 horas",
    },
  },
};

export default ptBR;
