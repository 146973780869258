import { useIntl } from "react-intl";

import { Divider } from "@hexa-ui/components";

import {
  PocData,
  PocInformationDetails,
} from "../../types/pocInformationTypes";
import GenericInformation from "../genericInformation/GenericInformation";
import * as Styles from "./PocInformationBody.styles";

interface PocInformationBody {
  pocDetails: PocInformationDetails;
}

const RenderPocInformation = (pocInformation: PocData[]) => {
  return pocInformation.map((data) => {
    const cleanValue = data?.value?.replace(/\s/g, "").replace(/\\n/g, "");
    const exhibition: boolean = cleanValue && cleanValue !== "undefined";

    return (
      exhibition && (
        <GenericInformation
          basicData={data}
          key={`${data.title} - ${data.value}`}
        />
      )
    );
  });
};

export default function PocInformationBody({
  pocDetails,
}: Readonly<PocInformationBody>) {
  const { formatMessage } = useIntl();
  const { pocInformationData, salesStructureData } = pocDetails;

  return (
    <Styles.Wrapper data-testid={`${PocInformationBody.name}`}>
      <Styles.Container>
        <Styles.BodyTitle
          data-testid={`${PocInformationBody.name}-poc-information-title`}
          size="H5"
        >
          {formatMessage({ id: "PocInformation.POC_INFORMATION_TITLE" })}
        </Styles.BodyTitle>
        <Styles.Content>
          {RenderPocInformation(pocInformationData)}
        </Styles.Content>
      </Styles.Container>
      <Divider orientation="vertical" />
      <Styles.Container>
        <Styles.BodyTitle
          size="H5"
          data-testid={`${PocInformationBody.name}-sales-structure-title`}
        >
          {formatMessage({ id: "PocInformation.SALES_STRUCTURE_TITLE" })}
        </Styles.BodyTitle>
        <Styles.Content>
          {RenderPocInformation(salesStructureData)}
        </Styles.Content>
      </Styles.Container>
    </Styles.Wrapper>
  );
}
