export const callLaterOptions = [
  {
    value: "0.25",
    label: "CallTypification.15_MINUTES",
  },
  {
    value: "0.5",
    label: "CallTypification.30_MINUTES",
  },
  {
    value: "1",
    label: "CallTypification.1_HOUR",
  },
  {
    value: "1.5",
    label: "CallTypification.1_HOUR_AND_30_MINUTES",
  },
  {
    value: "2",
    label: "CallTypification.2_HOURS",
  },
  {
    value: "2.5",
    label: "CallTypification.2_HOUR_AND_30_MINUTES",
  },
  {
    value: "3",
    label: "CallTypification.3_HOURS",
  },
  {
    value: "3.5",
    label: "CallTypification.3_HOUR_AND_30_MINUTES",
  },
  {
    value: "4",
    label: "CallTypification.4_HOURS",
  },
  {
    value: "4.5",
    label: "CallTypification.4_HOUR_AND_30_MINUTES",
  },
  {
    value: "5",
    label: "CallTypification.5_HOURS",
  },
  {
    value: "5.5",
    label: "CallTypification.5_HOUR_AND_30_MINUTES",
  },
  {
    value: "6",
    label: "CallTypification.6_HOURS",
  },
  {
    value: "6.5",
    label: "CallTypification.6_HOUR_AND_30_MINUTES",
  },
  {
    value: "7",
    label: "CallTypification.7_HOURS",
  },
  {
    value: "7.5",
    label: "CallTypification.7_HOUR_AND_30_MINUTES",
  },
  {
    value: "8",
    label: "CallTypification.8_HOURS",
  },
];
