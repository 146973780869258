import { createEffect } from "effector";

import { CreditDomain } from "@/domains/CreditDomain";
import { getCreditUseCase } from "@/useCases/credit/getCredit/GetCreditUseCase";

export interface GetCreditProps {
  accountId: string;
  vendorId: string;
}

export const getCredit = createEffect({
  async handler(props: GetCreditProps): Promise<CreditDomain> {
    return await getCreditUseCase(props);
  },
});
