import { useEffect, useMemo, useRef, useState } from "react";

import {
  agentCallSharedService,
  growSettingsSharedService,
  useSharedUnit,
} from "@bees-grow-shared/services";
import Moment from "moment";

import { TimerValue } from "@/@types/TimeValue";
import { convertTimeInSecond } from "@/utils/convertTimeInSecond/convertTimeInSecond";

interface useCallTimeSyncProps {
  value: TimerValue;
}

export function useCallTimer(): useCallTimeSyncProps {
  const agentCallService = agentCallSharedService();
  const growSettingsService = growSettingsSharedService();

  const { startCallDate, isCallValid } = useSharedUnit(agentCallService);
  const { minCallDuration } = useSharedUnit(growSettingsService);
  const [timerValue, setTimerValue] = useState<TimerValue>({
    ms: 0,
    s: 0,
    m: 0,
    h: 0,
    d: 0,
    state: "INITED",
  });

  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const callIsValid = useMemo(
    () => Math.floor(convertTimeInSecond(timerValue)) >= minCallDuration,
    [minCallDuration, timerValue],
  );

  useEffect(() => {
    if (callIsValid && !isCallValid) {
      agentCallService.setStartCallState({
        isCallValid: callIsValid,
      });
    }
  }, [agentCallService, callIsValid, isCallValid]);

  useEffect(() => {
    if (!startCallDate) return;

    const updateTimer = () => {
      const elapsedTime = Math.abs(
        Moment.utc().diff(Moment.utc(startCallDate)),
      );
      const ms = elapsedTime % 1000;
      const s = Math.floor((elapsedTime / 1000) % 60);
      const m = Math.floor((elapsedTime / (1000 * 60)) % 60);
      const h = Math.floor((elapsedTime / (1000 * 60 * 60)) % 24);
      const d = Math.floor(elapsedTime / (1000 * 60 * 60 * 24));

      setTimerValue({ ms, s, m, h, d, state: "PLAYING" });
    };

    intervalRef.current = setInterval(updateTimer, 1000);
    updateTimer();

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [startCallDate]);

  return { value: timerValue };
}
