import { useEffect, useMemo } from "react";

import {
  accountSharedService,
  agentCallSharedService,
  useCurrencyFormatter,
  useSharedUnit,
} from "@bees-grow-shared/services";
import { useUnit } from "effector-react";

import { getGap } from "@/stores/gap/GapEvents";
import GapStore from "@/stores/gap/GapStore";

interface useTopLineGapProps {
  forecastFormatted: string;
  gapFormatted: string;
  percentage: number;
  isLoading: boolean;
  errorDisable: boolean;
}

export function useTopLineGap(): useTopLineGapProps {
  const { gap, forecast, percentage, isLoading, error } = useUnit(GapStore);
  const accountService = accountSharedService();
  const { accountId, vendorId } = useSharedUnit(accountService);
  const agentCallService = agentCallSharedService();
  const { callId } = useSharedUnit(agentCallService);

  const minimumFractionDigits = 2;

  useEffect(() => {
    if (accountId && vendorId) {
      getGap({ accountId, vendorId });
    }
  }, [accountId, vendorId, callId]);

  const gapFormatted = useCurrencyFormatter(gap, {
    minimumFractionDigits,
  });

  const forecastFormatted = useCurrencyFormatter(forecast, {
    minimumFractionDigits,
  });

  const errorDisable = useMemo(() => error || gap === 0, [error, gap]);

  return {
    forecastFormatted,
    gapFormatted,
    percentage,
    isLoading,
    errorDisable,
  };
}
