import { AttemptsDomain } from "@/domains/AttemptsDomain";

export interface OpenCallState extends AttemptsDomain {
  isLoading: boolean;
  error: boolean;
  done: boolean;
  isStartedCall: boolean;
  isModalOpen: boolean;
}

export const openCallStoreInitialState: OpenCallState = {
  isLoading: false,
  error: false,
  done: false,

  callAttempts: 0,
  isStartedCall: false,
  isModalOpen: false,
};
