import { createStore } from "effector";

import { getGap } from "./GapEvents";
import { gapInitialState, GapState } from "./GapState";

const GapStore = createStore(gapInitialState)
  .on(getGap, (state: GapState): GapState => {
    return {
      ...state,
      isLoading: true,
      done: false,
      error: false,
    };
  })
  .on(
    getGap.fail,
    (state: GapState): GapState => ({
      ...state,
      isLoading: false,
      done: true,
      error: true,
    }),
  )
  .on(getGap.done, (state: GapState, { result }): GapState => {
    return {
      ...state,
      ...result,
      isLoading: false,
      done: true,
      error: false,
    };
  });

export default GapStore;
