import { tokens } from "@bees-grow-shared/theme";
import { Paragraph } from "@hexa-ui/components";
import styled from "styled-components";

export const Title = styled(Paragraph)`
  color: ${tokens.color.foundation.txt.default};
  margin-bottom: ${tokens.measure.space["spacing-1"]};
  line-height: 1.25rem;
`;

export const WrapperValue = styled.div`
  display: flex;
  align-items: center;
  gap: ${tokens.measure.space["spacing-1"]};
`;

export const Value = styled(Paragraph)`
  color: ${tokens.color.foundation.txt.default};
  word-break: break-word;
  overflow-wrap: break-word;
  max-width: 100%;
  white-space: pre-line;
`;

export const ClipboardContainer = styled.div`
  width: 24px;
  margin: 0;
  padding: 0;
  height: 24px;
  min-width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    border-radius: ${tokens.measure["border-radius"]["borderradius-1"]};
    background: ${tokens.color.primitive.solid.neutral["neutral-30"]};
  }
`;
