const addMinutesToDate = (date: Date, minutes: number): Date => {
  return new Date(date.getTime() + minutes * 60 * 1000);
};

export const calculateCallLaterTime = (callLaterHour: number): string => {
  if (callLaterHour) {
    const currentTime = new Date();
    const minutes = callLaterHour * 60;
    const newTime = addMinutesToDate(currentTime, minutes);
    return newTime.toISOString();
  }

  return "";
};
