import { GapDomain } from "@/domains/GapDomain";
import { getGap } from "@/services/gap/getGap/GetGapService";
import { getGapConvert } from "@/useCases/gap/getGap/converters/GetGapConverter";

interface GetGapProps {
  accountId: string;
  vendorId: string;
}

export const getGapUseCase = async ({
  accountId,
  vendorId,
}: GetGapProps): Promise<GapDomain> => {
  const response = await getGap({
    path: { accountId },
    headers: { vendorId },
  });

  return getGapConvert(response);
};
