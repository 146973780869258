/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable no-console */
/* eslint-disable camelcase */
/**
 * This client was automatically generated by Segment Typewriter. ** Do Not Edit **
 */

/**
 * Ajv is a peer dependency for development builds. It's used to apply run-time validation
 * to message payloads before passing them on to the underlying analytics instance.
 *
 * Note that the production bundle does not depend on Ajv.
 *
 * You can install it with: `npm install --save-dev ajv`.
 */
import Ajv from "ajv";

import * as Segment from "./segment";

export interface ButtonClicked {
  /**
   * This property represents the Label displayed to the user
   */
  button_label: string | null;
  /**
   * This property is the name of button
   */
  button_name: string | null;
  /**
   * This property is the actual call id.
   */
  call_id?: string | null;
  /**
   * The component that was collapsed or expanded
   */
  component_name?: string | null;
  /**
   * This property is the account contry.
   */
  country?: string | null;
  /**
   * This property is the selected customer account id.
   */
  customer_account_id?: string | null;
  /**
   * This property is the device selected language.
   */
  device_language?: string | null;
  /**
   * This property is the user email.
   */
  email?: string | null;
  /**
   * This property is the organization id.
   */
  org_id?: string | null;
  /**
   * This property is the actual user screen name.
   */
  screen_name: string | null;
  /**
   * This property is the content searched.
   */
  search_query: string | null;
  /**
   * This property is the name of the task clicked.
   */
  task_name?: string | null;
  /**
   * This property is the priority of the task clicked.
   */
  task_priority?: number | null;
  /**
   * This property is the destination url of the button.
   */
  url: string | null;
  /**
   * This property is the user selected language.
   */
  user_selected_language?: string | null;
  /**
   * This property is the vendor account id.
   */
  vendor_account_id?: string | null;
  /**
   * This property is the vendor id.
   */
  vendor_id?: string | null;
}
export interface CallEnded {
  /**
   * This property is the POC source
   */
  call_origin: string;
  /**
   * The classification of call as Success on contact, Responsible not available...
   */
  contact_classification: string | null;
  /**
   * The button used to finish the call
   */
  finisher_button?: string;
  /**
   * The comments collected in the call summary
   */
  general_comments?: string | null;
}
export interface TasksItem {
  /**
   * This property is the task name
   */
  name?: string | null;
  /**
   * This property is the task priortiy
   */
  priority?: number | null;
}
export interface CallTasksLoaded {
  /**
   * This property is the actual call id.
   */
  call_id?: string | null;
  /**
   * The component that was collapsed or expanded
   */
  component_name?: string | null;
  /**
   * This property is the account contry.
   */
  country?: string | null;
  /**
   * This property is the selected customer account id.
   */
  customer_account_id?: string | null;
  /**
   * This property is the device selected language.
   */
  device_language?: string | null;
  /**
   * This property is the user email.
   */
  email?: string | null;
  /**
   * This property is the organization id.
   */
  org_id?: string | null;
  /**
   * This property is the actual user screen name.
   */
  screen_name: string | null;
  /**
   * Object with tasks loaded during the call.
   */
  tasks: TasksItem[] | null;
  /**
   * The quantity of tasks was presented during this interaction.
   */
  tasks_quantity?: number | null;
  /**
   * This property is the user selected language.
   */
  user_selected_language?: string | null;
  /**
   * This property is the vendor account id.
   */
  vendor_account_id?: string | null;
  /**
   * This property is the vendor id.
   */
  vendor_id?: string | null;
}
export interface CollapseAndExpandSectionClicked {
  /**
   * The action to send must contain the desired action
   */
  component_action?: string | null;
  /**
   * The component that was collapsed or expanded
   */
  component_name?: string | null;
}
export interface ErroEvent {
  /**
   * This property is the actual call id.
   */
  call_id?: string | null;
  /**
   * The component that was collapsed or expanded
   */
  component_name?: string | null;
  /**
   * This property is the account contry.
   */
  country?: string | null;
  /**
   * This property is the selected customer account id.
   */
  customer_account_id?: string | null;
  /**
   * This property is the device selected language.
   */
  device_language?: string | null;
  /**
   * This property is the user email.
   */
  email?: string | null;
  /**
   * This property is the error message.
   */
  error_message: string | null;
  /**
   * This property is the error type.
   */
  error_type: string | null;
  /**
   * This property is the organization id.
   */
  org_id?: string | null;
  /**
   * This property is the actual user screen name.
   */
  screen_name: string | null;
  /**
   * This property is the user selected language.
   */
  user_selected_language?: string | null;
  /**
   * This property is the vendor account id.
   */
  vendor_account_id?: string | null;
  /**
   * This property is the vendor id.
   */
  vendor_id?: string | null;
}
export interface ProductAdded {
  /**
   * This property is the actual call id.
   */
  call_id?: string | null;
  /**
   * This property is the product category.
   */
  category?: string | null;
  /**
   * The component that was collapsed or expanded
   */
  component_name?: string | null;
  /**
   * This property is the account contry.
   */
  country?: string | null;
  /**
   * This property is the selected customer account id.
   */
  customer_account_id?: string | null;
  /**
   * This property is the device selected language.
   */
  device_language?: string | null;
  /**
   * This property is the user email.
   */
  email?: string | null;
  /**
   * If product is part of any mission displayed during the call.
   */
  is_product_task?: boolean | null;
  /**
   * This property is the organization id.
   */
  org_id?: string | null;
  /**
   * This property is the platform code of the product.
   */
  platform_item_id: string | null;
  /**
   * This property is the name of product added.
   */
  product_name: string | null;
  /**
   * This property is if the product has a promotion.
   */
  promotion_type?: string;
  /**
   * This property is the quantity items in stock.
   */
  quantity?: number | null;
  /**
   * This property is the actual user screen name.
   */
  screen_name: string | null;
  /**
   * This property is the sku code of product added.
   */
  sku: string | null;
  /**
   * This property is the quantity suggested by Quick Order(Algo Selling).
   */
  suggested_quantity?: number | null;
  /**
   * This property is the user selected language.
   */
  user_selected_language?: string | null;
  /**
   * This property is the variant added in the cases of it's required.
   */
  variant_type?: string | null;
  /**
   * This property is the vendor account id.
   */
  vendor_account_id?: string | null;
  /**
   * This property is the vendor id.
   */
  vendor_id?: string | null;
  /**
   * This property is the vendor item id of the product.
   */
  vendor_item_id?: string | null;
}
export interface ProductsItem {
  /**
   * This property is the product category
   */
  category?: string | null;
  /**
   * This property is the platform code of the product
   */
  platform_item_id?: string | null;
  /**
   * This property is the name of product added
   */
  product_name?: string | null;
  /**
   * This property is the quantity items in stock
   */
  product_quantity?: number | null;
  /**
   * This property is if product is part of any mission displayed during the call
   */
  product_task?: boolean | null;
  /**
   * This property is if the product has a promotion
   */
  promotion_type?: string;
  /**
   * This property is the code of product added
   */
  sku?: string | null;
  /**
   * This property is the quantity suggested by Quick Order(Algo Selling)
   */
  suggested_quantity?: number | null;
  /**
   * This property is the variant added in the cases of it's required
   */
  variant_type?: string | null;
  /**
   * This property is the vendor item id of the product
   */
  vendor_item_id?: string | null;
}
export interface ProductsLoaded {
  /**
   * This property is the actual call id.
   */
  call_id?: string | null;
  /**
   * The component that was collapsed or expanded
   */
  component_name?: string | null;
  /**
   * This property is the account contry.
   */
  country?: string | null;
  /**
   * This property is the selected customer account id.
   */
  customer_account_id?: string | null;
  /**
   * This property is the device selected language.
   */
  device_language?: string | null;
  /**
   * This property is the user email.
   */
  email?: string | null;
  /**
   * This property is the organization id.
   */
  org_id?: string | null;
  /**
   * All the products that are loaded
   */
  products: ProductsItem[] | null;
  /**
   * This property is the actual user screen name.
   */
  screen_name: string | null;
  /**
   * This property is in the case of b2b category must describe which category was.
   */
  source?: string;
  /**
   * This property is the user selected language.
   */
  user_selected_language?: string | null;
  /**
   * This property is the vendor account id.
   */
  vendor_account_id?: string | null;
  /**
   * This property is the vendor id.
   */
  vendor_id?: string | null;
}
export interface SearchByPocInteraction {
  /**
   * This property is the content searched.
   */
  search_query: string | null;
  /**
   * This property is to understand if the user prefers to use Enter or Click on the Search Icon.
   */
  search_trigger_method: string;
}
export interface SidebarMenuClicked {
  /**
   * This property represents the Label displayed to the user
   */
  button_label: string | null;
  /**
   * This property is the name of button
   */
  button_name: string | null;
  /**
   * If the menu is collapsed or not
   */
  collapsed_menu?: boolean | null;
  /**
   * Screen Source
   */
  screen_origin?: string | null;
}
export interface VendorSelection {
  /**
   * This property is the POC source
   */
  call_origin: string;
  /**
   * This property is the vendor selected getting a POC
   */
  vendor_selected: string | null;
}

export type ViolationHandler = (
  message: Record<string, any>,
  violations: Ajv.ErrorObject[],
) => void;

/**
 * The default handler that is fired if none is supplied with setTypewriterOptions.
 * This handler will log a warning message to the console.
 */
export const defaultValidationErrorHandler: ViolationHandler = (
  message,
  violations,
) => {
  const msg = JSON.stringify(
    {
      type: "Typewriter JSON Schema Validation Error",
      description:
        `You made an analytics call (${message.event}) using Typewriter that doesn't match the ` +
        "Tracking Plan spec.",
      errors: violations,
    },
    undefined,
    2,
  );

  console.warn(msg);
};

let onViolation = defaultValidationErrorHandler;

let analytics: () => SegmentAnalytics.AnalyticsJS | undefined = () => {
  return window.analytics;
};

/** Options to customize the runtime behavior of a Typewriter client. */
export interface TypewriterOptions {
  /**
   * Underlying analytics instance where analytics calls are forwarded on to.
   * Defaults to window.analytics.
   */
  analytics?: SegmentAnalytics.AnalyticsJS;
  /**
   * Handler fired when if an event does not match its spec. This handler
   * does not fire in production mode, because it requires inlining the full
   * JSON Schema spec for each event in your Tracking Plan.
   *
   * By default, it will throw errors if NODE_ENV = "test" so that tests will fail
   * if a message does not match the spec. Otherwise, errors will be logged to stderr.
   */
  onViolation?: ViolationHandler;
}

/**
 * Updates the run-time configuration of this Typewriter client.
 *
 * @param {TypewriterOptions} options - the options to upsert
 *
 * @typedef {Object} TypewriterOptions
 * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
 * 		calls are forwarded on to. Defaults to window.analytics.
 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
 * 		will be logged to stderr.
 */
export function setTypewriterOptions(options: TypewriterOptions) {
  analytics = options.analytics
    ? () => options.analytics || window.analytics
    : analytics;
  onViolation = options.onViolation || onViolation;
}

/**
 * Validates a message against a JSON Schema using Ajv. If the message
 * is invalid, the `onViolation` handler will be called.
 */
function validateAgainstSchema(message: Record<string, any>, schema: object) {
  const ajv = new Ajv({ schemaId: "auto", allErrors: true, verbose: true });
  ajv.addMetaSchema(require("ajv/lib/refs/json-schema-draft-06.json"));
  ajv.addMetaSchema(require("ajv/lib/refs/json-schema-draft-04.json"));

  if (!ajv.validate(schema, message) && ajv.errors) {
    onViolation(message, ajv.errors);
  }
}

/**
 * Helper to attach metadata on Typewriter to outbound requests.
 * This is used for attribution and debugging by the Segment team.
 */
function withTypewriterContext(message: Segment.Options = {}): Segment.Options {
  return {
    ...message,
    context: {
      ...(message.context || {}),
      typewriter: {
        language: "typescript",
        version: "7.4.1",
      },
    },
  };
}

/**
 * @typedef ButtonClicked
 * @property {string | null} button_label - This property represents the Label displayed to the user
 * @property {string | null} button_name - This property is the name of button
 * @property {string | null} [call_id] - This property is the actual call id.
 * @property {string | null} [component_name] - The component that was collapsed or expanded
 * @property {string | null} [country] - This property is the account contry.
 * @property {string | null} [customer_account_id] - This property is the selected customer account id.
 * @property {string | null} [device_language] - This property is the device selected language.
 * @property {string | null} [email] - This property is the user email.
 * @property {string | null} [org_id] - This property is the organization id.
 * @property {string | null} screen_name - This property is the actual user screen name.
 * @property {string | null} search_query - This property is the content searched.
 * @property {string | null} [task_name] - This property is the name of the task clicked.
 * @property {number | null} [task_priority] - This property is the priority of the task clicked.
 * @property {string | null} url - This property is the destination url of the button.
 * @property {string | null} [user_selected_language] - This property is the user selected language.
 * @property {string | null} [vendor_account_id] - This property is the vendor account id.
 * @property {string | null} [vendor_id] - This property is the vendor id.
 */
/**
 * @typedef CallEnded
 * @property {string} call_origin - This property is the POC source
 * @property {string | null} contact_classification - The classification of call as Success on contact, Responsible not available...
 * @property {string} [finisher_button] - The button used to finish the call
 * @property {string | null} [general_comments] - The comments collected in the call summary
 */
/**
 * @typedef TasksItem
 * @property {string | null} [name] - This property is the task name
 * @property {number | null} [priority] - This property is the task priortiy
 */
/**
 * @typedef CallTasksLoaded
 * @property {string | null} [call_id] - This property is the actual call id.
 * @property {string | null} [component_name] - The component that was collapsed or expanded
 * @property {string | null} [country] - This property is the account contry.
 * @property {string | null} [customer_account_id] - This property is the selected customer account id.
 * @property {string | null} [device_language] - This property is the device selected language.
 * @property {string | null} [email] - This property is the user email.
 * @property {string | null} [org_id] - This property is the organization id.
 * @property {string | null} screen_name - This property is the actual user screen name.
 * @property {TasksItem[] | null} tasks - Object with tasks loaded during the call.
 * @property {number | null} [tasks_quantity] - The quantity of tasks was presented during this interaction.
 * @property {string | null} [user_selected_language] - This property is the user selected language.
 * @property {string | null} [vendor_account_id] - This property is the vendor account id.
 * @property {string | null} [vendor_id] - This property is the vendor id.
 */
/**
 * @typedef CollapseAndExpandSectionClicked
 * @property {string | null} [component_action] - The action to send must contain the desired action
 * @property {string | null} [component_name] - The component that was collapsed or expanded
 */
/**
 * @typedef ErroEvent
 * @property {string | null} [call_id] - This property is the actual call id.
 * @property {string | null} [component_name] - The component that was collapsed or expanded
 * @property {string | null} [country] - This property is the account contry.
 * @property {string | null} [customer_account_id] - This property is the selected customer account id.
 * @property {string | null} [device_language] - This property is the device selected language.
 * @property {string | null} [email] - This property is the user email.
 * @property {string | null} error_message - This property is the error message.
 * @property {string | null} error_type - This property is the error type.
 * @property {string | null} [org_id] - This property is the organization id.
 * @property {string | null} screen_name - This property is the actual user screen name.
 * @property {string | null} [user_selected_language] - This property is the user selected language.
 * @property {string | null} [vendor_account_id] - This property is the vendor account id.
 * @property {string | null} [vendor_id] - This property is the vendor id.
 */
/**
 * @typedef ProductAdded
 * @property {string | null} [call_id] - This property is the actual call id.
 * @property {string | null} [category] - This property is the product category.
 * @property {string | null} [component_name] - The component that was collapsed or expanded
 * @property {string | null} [country] - This property is the account contry.
 * @property {string | null} [customer_account_id] - This property is the selected customer account id.
 * @property {string | null} [device_language] - This property is the device selected language.
 * @property {string | null} [email] - This property is the user email.
 * @property {boolean | null} [is_product_task] - If product is part of any mission displayed during the call.
 * @property {string | null} [org_id] - This property is the organization id.
 * @property {string | null} platform_item_id - This property is the platform code of the product.
 * @property {string | null} product_name - This property is the name of product added.
 * @property {string} [promotion_type] - This property is if the product has a promotion.
 * @property {number | null} [quantity] - This property is the quantity items in stock.
 * @property {string | null} screen_name - This property is the actual user screen name.
 * @property {string | null} sku - This property is the sku code of product added.
 * @property {number | null} [suggested_quantity] - This property is the quantity suggested by Quick Order(Algo Selling).
 * @property {string | null} [user_selected_language] - This property is the user selected language.
 * @property {string | null} [variant_type] - This property is the variant added in the cases of it's required.
 * @property {string | null} [vendor_account_id] - This property is the vendor account id.
 * @property {string | null} [vendor_id] - This property is the vendor id.
 * @property {string | null} [vendor_item_id] - This property is the vendor item id of the product.
 */
/**
 * @typedef ProductsItem
 * @property {string | null} [category] - This property is the product category
 * @property {string | null} [platform_item_id] - This property is the platform code of the product
 * @property {string | null} [product_name] - This property is the name of product added
 * @property {number | null} [product_quantity] - This property is the quantity items in stock
 * @property {boolean | null} [product_task] - This property is if product is part of any mission displayed during the call
 * @property {string} [promotion_type] - This property is if the product has a promotion
 * @property {string | null} [sku] - This property is the code of product added
 * @property {number | null} [suggested_quantity] - This property is the quantity suggested by Quick Order(Algo Selling)
 * @property {string | null} [variant_type] - This property is the variant added in the cases of it's required
 * @property {string | null} [vendor_item_id] - This property is the vendor item id of the product
 */
/**
 * @typedef ProductsLoaded
 * @property {string | null} [call_id] - This property is the actual call id.
 * @property {string | null} [component_name] - The component that was collapsed or expanded
 * @property {string | null} [country] - This property is the account contry.
 * @property {string | null} [customer_account_id] - This property is the selected customer account id.
 * @property {string | null} [device_language] - This property is the device selected language.
 * @property {string | null} [email] - This property is the user email.
 * @property {string | null} [org_id] - This property is the organization id.
 * @property {ProductsItem[] | null} products - All the products that are loaded
 * @property {string | null} screen_name - This property is the actual user screen name.
 * @property {string} [source] - This property is in the case of b2b category must describe which category was.
 * @property {string | null} [user_selected_language] - This property is the user selected language.
 * @property {string | null} [vendor_account_id] - This property is the vendor account id.
 * @property {string | null} [vendor_id] - This property is the vendor id.
 */
/**
 * @typedef SearchByPocInteraction
 * @property {string | null} search_query - This property is the content searched.
 * @property {string} search_trigger_method - This property is to understand if the user prefers to use Enter or Click on the Search Icon.
 */
/**
 * @typedef SidebarMenuClicked
 * @property {string | null} button_label - This property represents the Label displayed to the user
 * @property {string | null} button_name - This property is the name of button
 * @property {boolean | null} [collapsed_menu] - If the menu is collapsed or not
 * @property {string | null} [screen_origin] - Screen Source
 */
/**
 * @typedef VendorSelection
 * @property {string} call_origin - This property is the POC source
 * @property {string | null} vendor_selected - This property is the vendor selected getting a POC
 */

/**
 * The event is generic and support all buttons being identified by the label and name.
 *
 * @param {ButtonClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function buttonClicked(
  props: ButtonClicked,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: "http://json-schema.org/draft-07/schema#",
    description:
      "The event is generic and support all buttons being identified by the label and name.",
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          button_label: {
            description:
              "This property represents the Label displayed to the user",
            type: ["string", "null"],
          },
          button_name: {
            description: "This property is the name of button",
            type: ["string", "null"],
          },
          call_id: {
            description: "This property is the actual call id.",
            type: ["string", "null"],
          },
          component_name: {
            description: "The component that was collapsed or expanded",
            type: ["string", "null"],
          },
          country: {
            description: "This property is the account contry.",
            type: ["string", "null"],
          },
          customer_account_id: {
            description: "This property is the selected customer account id.",
            type: ["string", "null"],
          },
          device_language: {
            description: "This property is the device selected language.",
            type: ["string", "null"],
          },
          email: {
            description: "This property is the user email.",
            type: ["string", "null"],
          },
          org_id: {
            description: "This property is the organization id.",
            type: ["string", "null"],
          },
          screen_name: {
            description: "This property is the actual user screen name.",
            type: ["string", "null"],
          },
          search_query: {
            description: "This property is the content searched.",
            type: ["string", "null"],
          },
          task_name: {
            description: "This property is the name of the task clicked.",
            type: ["string", "null"],
          },
          task_priority: {
            description: "This property is the priority of the task clicked.",
            type: ["integer", "null"],
          },
          url: {
            description: "This property is the destination url of the button.",
            type: ["string", "null"],
          },
          user_selected_language: {
            description: "This property is the user selected language.",
            type: ["string", "null"],
          },
          vendor_account_id: {
            description: "This property is the vendor account id.",
            type: ["string", "null"],
          },
          vendor_id: {
            description: "This property is the vendor id.",
            type: ["string", "null"],
          },
        },
        required: [
          "screen_name",
          "button_label",
          "button_name",
          "url",
          "search_query",
        ],
        type: "object",
      },
      traits: {},
    },
    required: ["properties"],
    title: "Button Clicked",
    type: "object",
  };
  const message = {
    event: "Button Clicked",
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      "Button Clicked",
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * The event will identify when the user ended call
 *
 * @param {CallEnded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function callEnded(
  props: CallEnded,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: "http://json-schema.org/draft-07/schema#",
    description: "The event will identify when the user ended call",
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          call_origin: {
            description: "This property is the POC source",
            enum: ["SEARCH_BY_POC", "AUTO_DIALER", "GO_TO_NEXT_POC"],
            type: ["string", "null"],
          },
          contact_classification: {
            description:
              "The classification of call as Success on contact, Responsible not available...",
            type: ["string", "null"],
          },
          finisher_button: {
            description: "The button used to finish the call",
            enum: ["TAKE_BREAK", "CALL_NEXT_POC", "GO_HOME"],
            type: ["string", "null"],
          },
          general_comments: {
            description: "The comments collected in the call summary",
            type: ["string", "null"],
          },
        },
        required: ["contact_classification", "call_origin"],
        type: "object",
      },
      traits: {},
    },
    required: ["properties"],
    title: "Call Ended",
    type: "object",
  };
  const message = {
    event: "Call Ended",
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      "Call Ended",
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * Measure the quantity of tasks was presented in each call.
 *
 * @param {CallTasksLoaded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function callTasksLoaded(
  props: CallTasksLoaded,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: "http://json-schema.org/draft-07/schema#",
    description: "Measure the quantity of tasks was presented in each call.",
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          call_id: {
            description: "This property is the actual call id.",
            type: ["string", "null"],
          },
          component_name: {
            description: "The component that was collapsed or expanded",
            type: ["string", "null"],
          },
          country: {
            description: "This property is the account contry.",
            type: ["string", "null"],
          },
          customer_account_id: {
            description: "This property is the selected customer account id.",
            type: ["string", "null"],
          },
          device_language: {
            description: "This property is the device selected language.",
            type: ["string", "null"],
          },
          email: {
            description: "This property is the user email.",
            type: ["string", "null"],
          },
          org_id: {
            description: "This property is the organization id.",
            type: ["string", "null"],
          },
          screen_name: {
            description: "This property is the actual user screen name.",
            type: ["string", "null"],
          },
          tasks: {
            description: "Object with tasks loaded during the call.",
            items: {
              description: "An individual task in the list",
              properties: {
                name: {
                  description: "This property is the task name",
                  type: ["string", "null"],
                },
                priority: {
                  description: "This property is the task priortiy",
                  type: ["integer", "null"],
                },
              },
              required: [],
              type: ["object"],
            },
            type: ["array", "null"],
          },
          tasks_quantity: {
            description:
              "The quantity of tasks was presented during this interaction.",
            type: ["integer", "null"],
          },
          user_selected_language: {
            description: "This property is the user selected language.",
            type: ["string", "null"],
          },
          vendor_account_id: {
            description: "This property is the vendor account id.",
            type: ["string", "null"],
          },
          vendor_id: {
            description: "This property is the vendor id.",
            type: ["string", "null"],
          },
        },
        required: ["screen_name", "tasks"],
        type: "object",
      },
      traits: {},
    },
    required: ["properties"],
    title: "Call Tasks Loaded",
    type: "object",
  };
  const message = {
    event: "Call Tasks Loaded",
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      "Call Tasks Loaded",
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * User clicks to Collapse or Expand a Section
 *
 * @param {CollapseAndExpandSectionClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function collapseAndExpandSectionClicked(
  props: CollapseAndExpandSectionClicked,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: "http://json-schema.org/draft-07/schema#",
    description: "User clicks to Collapse or Expand a Section",
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          component_action: {
            description: "The action to send must contain the desired action",
            type: ["string", "null"],
          },
          component_name: {
            description: "The component that was collapsed or expanded",
            type: ["string", "null"],
          },
        },
        type: "object",
      },
      traits: {},
    },
    required: ["properties"],
    title: "Collapse and Expand Section Clicked",
    type: "object",
  };
  const message = {
    event: "Collapse and Expand Section Clicked",
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      "Collapse and Expand Section Clicked",
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * The error event is unique for all errors that the application has.
 *
 * @param {ErroEvent} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function erroEvent(
  props: ErroEvent,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: "http://json-schema.org/draft-07/schema#",
    description:
      "The error event is unique for all errors that the application has.",
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          call_id: {
            description: "This property is the actual call id.",
            type: ["string", "null"],
          },
          component_name: {
            description: "The component that was collapsed or expanded",
            type: ["string", "null"],
          },
          country: {
            description: "This property is the account contry.",
            type: ["string", "null"],
          },
          customer_account_id: {
            description: "This property is the selected customer account id.",
            type: ["string", "null"],
          },
          device_language: {
            description: "This property is the device selected language.",
            type: ["string", "null"],
          },
          email: {
            description: "This property is the user email.",
            type: ["string", "null"],
          },
          error_message: {
            description: "This property is the error message.",
            type: ["string", "null"],
          },
          error_type: {
            description: "This property is the error type.",
            type: ["string", "null"],
          },
          org_id: {
            description: "This property is the organization id.",
            type: ["string", "null"],
          },
          screen_name: {
            description: "This property is the actual user screen name.",
            type: ["string", "null"],
          },
          user_selected_language: {
            description: "This property is the user selected language.",
            type: ["string", "null"],
          },
          vendor_account_id: {
            description: "This property is the vendor account id.",
            type: ["string", "null"],
          },
          vendor_id: {
            description: "This property is the vendor id.",
            type: ["string", "null"],
          },
        },
        required: ["screen_name", "error_type", "error_message"],
        type: "object",
      },
      traits: {},
    },
    required: ["properties"],
    title: "Erro Event",
    type: "object",
  };
  const message = {
    event: "Erro Event",
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      "Erro Event",
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * Understand which products are being added, measure the influence of the tasks in the products added, measure the adherence of suggested quantity by products added and understand if the product inventory is a reason of limited the quantity added.
 *
 * @param {ProductAdded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productAdded(
  props: ProductAdded,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: "http://json-schema.org/draft-07/schema#",
    description:
      "Understand which products are being added, measure the influence of the tasks in the products added, measure the adherence of suggested quantity by products added and understand if the product inventory is a reason of limited the quantity added.",
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          call_id: {
            description: "This property is the actual call id.",
            type: ["string", "null"],
          },
          category: {
            description: "This property is the product category.",
            type: ["string", "null"],
          },
          component_name: {
            description: "The component that was collapsed or expanded",
            type: ["string", "null"],
          },
          country: {
            description: "This property is the account contry.",
            type: ["string", "null"],
          },
          customer_account_id: {
            description: "This property is the selected customer account id.",
            type: ["string", "null"],
          },
          device_language: {
            description: "This property is the device selected language.",
            type: ["string", "null"],
          },
          email: {
            description: "This property is the user email.",
            type: ["string", "null"],
          },
          is_product_task: {
            description:
              "If product is part of any mission displayed during the call.",
            type: ["boolean", "null"],
          },
          org_id: {
            description: "This property is the organization id.",
            type: ["string", "null"],
          },
          platform_item_id: {
            description: "This property is the platform code of the product.",
            type: ["string", "null"],
          },
          product_name: {
            description: "This property is the name of product added.",
            type: ["string", "null"],
          },
          promotion_type: {
            description: "This property is if the product has a promotion.",
            enum: ["DISCOUNT", "FREE_GOOD", "FIXED_COMBO", "INTERACTIVE_COMBO"],
            type: ["string", "null"],
          },
          quantity: {
            description: "This property is the quantity items in stock.",
            type: ["integer", "null"],
          },
          screen_name: {
            description: "This property is the actual user screen name.",
            type: ["string", "null"],
          },
          sku: {
            description: "This property is the sku code of product added.",
            type: ["string", "null"],
          },
          suggested_quantity: {
            description:
              "This property is the quantity suggested by Quick Order(Algo Selling).",
            type: ["integer", "null"],
          },
          user_selected_language: {
            description: "This property is the user selected language.",
            type: ["string", "null"],
          },
          variant_type: {
            description:
              "This property is the variant added in the cases of it's required.",
            type: ["string", "null"],
          },
          vendor_account_id: {
            description: "This property is the vendor account id.",
            type: ["string", "null"],
          },
          vendor_id: {
            description: "This property is the vendor id.",
            type: ["string", "null"],
          },
          vendor_item_id: {
            description: "This property is the vendor item id of the product.",
            type: ["string", "null"],
          },
        },
        required: ["screen_name", "product_name", "sku", "platform_item_id"],
        type: "object",
      },
      traits: {},
    },
    required: ["properties"],
    title: "Product Added",
    type: "object",
  };
  const message = {
    event: "Product Added",
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      "Product Added",
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * When the product selection is loaded.
 *
 * @param {ProductsLoaded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productsLoaded(
  props: ProductsLoaded,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: "http://json-schema.org/draft-07/schema#",
    description: "When the product selection is loaded.",
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          call_id: {
            description: "This property is the actual call id.",
            type: ["string", "null"],
          },
          component_name: {
            description: "The component that was collapsed or expanded",
            type: ["string", "null"],
          },
          country: {
            description: "This property is the account contry.",
            type: ["string", "null"],
          },
          customer_account_id: {
            description: "This property is the selected customer account id.",
            type: ["string", "null"],
          },
          device_language: {
            description: "This property is the device selected language.",
            type: ["string", "null"],
          },
          email: {
            description: "This property is the user email.",
            type: ["string", "null"],
          },
          org_id: {
            description: "This property is the organization id.",
            type: ["string", "null"],
          },
          products: {
            description: "All the products that are loaded",
            items: {
              description: "An individual product in the list",
              properties: {
                category: {
                  description: "This property is the product category",
                  type: ["string", "null"],
                },
                platform_item_id: {
                  description:
                    "This property is the platform code of the product",
                  type: ["string", "null"],
                },
                product_name: {
                  description: "This property is the name of product added",
                  type: ["string", "null"],
                },
                product_quantity: {
                  description: "This property is the quantity items in stock",
                  type: ["integer", "null"],
                },
                product_task: {
                  description:
                    "This property is if product is part of any mission displayed during the call",
                  type: ["boolean", "null"],
                },
                promotion_type: {
                  description:
                    "This property is if the product has a promotion",
                  enum: [
                    "DISCOUNT",
                    "FREE_GOOD",
                    "FIXED_COMBO",
                    "INTERACTIVE_COMBO",
                  ],
                  type: ["string", "null"],
                },
                sku: {
                  description: "This property is the code of product added",
                  type: ["string", "null"],
                },
                suggested_quantity: {
                  description:
                    "This property is the quantity suggested by Quick Order(Algo Selling)",
                  type: ["integer", "null"],
                },
                variant_type: {
                  description:
                    "This property is the variant added in the cases of it's required",
                  type: ["string", "null"],
                },
                vendor_item_id: {
                  description:
                    "This property is the vendor item id of the product",
                  type: ["string", "null"],
                },
              },
              required: [],
              type: ["object"],
            },
            type: ["array", "null"],
          },
          screen_name: {
            description: "This property is the actual user screen name.",
            type: ["string", "null"],
          },
          source: {
            description:
              "This property is in the case of b2b category must describe which category was.",
            enum: ["SUGGESTED_ORDER", "PROMOTIONS", "DEALS", "B2B_CATEGORY"],
            type: ["string", "null"],
          },
          user_selected_language: {
            description: "This property is the user selected language.",
            type: ["string", "null"],
          },
          vendor_account_id: {
            description: "This property is the vendor account id.",
            type: ["string", "null"],
          },
          vendor_id: {
            description: "This property is the vendor id.",
            type: ["string", "null"],
          },
        },
        required: ["screen_name", "products"],
        type: "object",
      },
      traits: {},
    },
    required: ["properties"],
    title: "Products Loaded",
    type: "object",
  };
  const message = {
    event: "Products Loaded",
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      "Products Loaded",
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * The event is to measure the usability of search by poc feature.
 *
 * @param {SearchByPocInteraction} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function searchByPocInteraction(
  props: SearchByPocInteraction,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: "http://json-schema.org/draft-07/schema#",
    description:
      "The event is to measure the usability of search by poc feature.",
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          search_query: {
            description: "This property is the content searched.",
            type: ["string", "null"],
          },
          search_trigger_method: {
            description:
              "This property is to understand if the user prefers to use Enter or Click on the Search Icon.",
            enum: ["ICON", "ENTER"],
            type: ["string", "null"],
          },
        },
        required: ["search_query", "search_trigger_method"],
        type: "object",
      },
      traits: {},
    },
    required: ["properties"],
    title: "Search by POC Interaction",
    type: "object",
  };
  const message = {
    event: "Search by POC Interaction",
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      "Search by POC Interaction",
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * User clicks on the Sidebar
 *
 * @param {SidebarMenuClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function sidebarMenuClicked(
  props: SidebarMenuClicked,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: "http://json-schema.org/draft-07/schema#",
    description: "User clicks on the Sidebar",
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          button_label: {
            description:
              "This property represents the Label displayed to the user",
            type: ["string", "null"],
          },
          button_name: {
            description: "This property is the name of button",
            type: ["string", "null"],
          },
          collapsed_menu: {
            description: "If the menu is collapsed or not",
            type: ["boolean", "null"],
          },
          screen_origin: {
            description: "Screen Source",
            type: ["string", "null"],
          },
        },
        required: ["button_label", "button_name"],
        type: "object",
      },
      traits: {},
    },
    required: ["properties"],
    title: "Sidebar Menu Clicked",
    type: "object",
  };
  const message = {
    event: "Sidebar Menu Clicked",
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      "Sidebar Menu Clicked",
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * The event will identify how many times the user needs to select a vendor.
 *
 * @param {VendorSelection} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function vendorSelection(
  props: VendorSelection,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: "http://json-schema.org/draft-07/schema#",
    description:
      "The event will identify how many times the user needs to select a vendor.",
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          call_origin: {
            description: "This property is the POC source",
            enum: ["SEARCH_BY_POC", "AUTO_DIALER", "GO_TO_NEXT_POC"],
            type: ["string", "null"],
          },
          vendor_selected: {
            description: "This property is the vendor selected getting a POC",
            type: ["string", "null"],
          },
        },
        required: ["vendor_selected", "call_origin"],
        type: "object",
      },
      traits: {},
    },
    required: ["properties"],
    title: "Vendor Selection",
    type: "object",
  };
  const message = {
    event: "Vendor Selection",
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      "Vendor Selection",
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}

const clientAPI = {
  /**
   * Updates the run-time configuration of this Typewriter client.
   *
   * @param {TypewriterOptions} options - the options to upsert
   *
   * @typedef {Object} TypewriterOptions
   * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
   * 		calls are forwarded on to. Defaults to window.analytics.
   * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
   * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
   * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
   * 		will be logged to stderr.
   */
  setTypewriterOptions,
  /**
   * The event is generic and support all buttons being identified by the label and name.
   *
   * @param {ButtonClicked} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  buttonClicked,
  /**
   * The event will identify when the user ended call
   *
   * @param {CallEnded} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  callEnded,
  /**
   * Measure the quantity of tasks was presented in each call.
   *
   * @param {CallTasksLoaded} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  callTasksLoaded,
  /**
   * User clicks to Collapse or Expand a Section
   *
   * @param {CollapseAndExpandSectionClicked} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  collapseAndExpandSectionClicked,
  /**
   * The error event is unique for all errors that the application has.
   *
   * @param {ErroEvent} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  erroEvent,
  /**
   * Understand which products are being added, measure the influence of the tasks in the products added, measure the adherence of suggested quantity by products added and understand if the product inventory is a reason of limited the quantity added.
   *
   * @param {ProductAdded} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  productAdded,
  /**
   * When the product selection is loaded.
   *
   * @param {ProductsLoaded} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  productsLoaded,
  /**
   * The event is to measure the usability of search by poc feature.
   *
   * @param {SearchByPocInteraction} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  searchByPocInteraction,
  /**
   * User clicks on the Sidebar
   *
   * @param {SidebarMenuClicked} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  sidebarMenuClicked,
  /**
   * The event will identify how many times the user needs to select a vendor.
   *
   * @param {VendorSelection} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  vendorSelection,
};

export default new Proxy<typeof clientAPI>(clientAPI, {
  get(target, method) {
    if (typeof method === "string" && target.hasOwnProperty(method)) {
      return target[method as keyof typeof clientAPI];
    }

    return () => {
      console.warn(`⚠️  You made an analytics call (${String(
        method,
      )}) that can't be found. Either:
    a) Re-generate your typewriter client: \`npx typewriter\`
    b) Add it to your Tracking Plan: https://app.segment.com/abi-delta/protocols/tracking-plans/rs_2k3p3PPPHbquyKSXOjxVzRsHOXm`);
      const a = analytics();
      if (a) {
        a.track(
          "Unknown Analytics Call Fired",
          {
            method,
          },
          withTypewriterContext(),
        );
      }
    };
  },
});
