import { useIntl } from "react-intl";

import { Divider } from "@hexa-ui/components";

import CreditContent from "../creditContent/CreditContent";
import * as Styles from "./CreditBody.styles";

interface CreditBodyProps {
  data: {
    totalLimit: string;
    creditUsed: string;
    overdue: string;
  };
}

export default function CreditBody({ data }: Readonly<CreditBodyProps>) {
  const { formatMessage } = useIntl();

  return (
    <Styles.Wrapper data-testid={`${CreditBody.name}`}>
      <Styles.Container>
        <Styles.Content>
          <CreditContent
            basicData={{
              title: formatMessage({
                id: "CreditAndPoints.Credit.TOTAL_LIMIT",
              }),
              value: data.totalLimit,
            }}
            key={`${CreditBody.name}-total-limit`}
            data-testid={`${CreditBody.name}-total-limit`}
          />
          <CreditContent
            basicData={{
              title: formatMessage({
                id: "CreditAndPoints.Credit.CREDIT_USE",
              }),
              value: `- ${data.creditUsed}`,
            }}
            key={`${CreditBody.name}-credit-use`}
            data-testid={`${CreditBody.name}-credit-use`}
          />
        </Styles.Content>
      </Styles.Container>
      <Divider orientation="horizontal" />
      <Styles.Container>
        <Styles.Content>
          <CreditContent
            basicData={{
              title: formatMessage({
                id: "CreditAndPoints.Credit.OVERDUE",
              }),
              value: data.overdue,
            }}
            key={`${CreditBody.name}-overdue`}
            data-testid={`${CreditBody.name}-overdue`}
          />
        </Styles.Content>
      </Styles.Container>
    </Styles.Wrapper>
  );
}
